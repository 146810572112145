import bookfile from '../utils/bookfile';
import translate from '../utils/translate';
import axios from 'axios';
//axios.defaults.headers.get['User-Agent'] = 'Mozilla/5.0 (Windows NT 6.1; WOW64; rv:33.0) Gecko/20120101 Firefox/33.0';
//axios.defaults.headers.post['User-Agent'] = 'Mozilla/5.0 (Windows NT 6.1; WOW64; rv:33.0) Gecko/20120101 Firefox/33.0';
var _ = require('lodash');
var HTMLParser = require('fast-html-parser');
var iconv = require('iconv-lite');
class shu69 {
    getListType =(web, successCallBack, failCallBack) => {
        axios.get("http://www.69shu.com/shuku/",{ responseType: 'arraybuffer' })
            .then(function (response) {
                response.data=iconv.decode(new Buffer(response.data,'gbk'), 'gbk');
                let doc = HTMLParser.parse(response.data);
                let as = doc.querySelectorAll("#content table.grid td.tdr a");
                let ts = [];
                for (let i = 0; i < as.length; i++) {
                    let a = as[i];
                    let obj = { typename: translate.ToFirstUpper(translate.ChineseToHanVietForBrowser(a.removeWhitespace().text.trim())), url: a.attributes.href, order: i, maxpage: null };
                    if (obj.url !== null && obj.url.indexOf("http") === -1 && obj.url.indexOf("www") === -1) {
                        obj.url = bookfile.ww.uurl + obj.url;
                    }
                    ts.push(obj);
                }
                successCallBack(ts)
            })
            .catch(function (error) {
                failCallBack(error);
            });
    }
    ListBooks(params, successCallBack, failCallBack) {
        let url = params.url;
        let uurl = bookfile.ww.uurl;
        if (url === "") { url = uurl; }
        let books = [];
        let page = parseInt(params.page, 10);
        if (page > 1) {
            url = url.replace("1.htm",page+".htm");
        }
        axios(url, {
            method: 'GET',
            responseType: 'arraybuffer'
        }).then((response) => response.data)
            .then((responseJson) => {
                responseJson=iconv.decode(new Buffer(responseJson,'gbk'), 'gbk');
                if (responseJson != null) {
                    let document = HTMLParser.parse(responseJson);
                    let p = 1;
                    try {
                        let a = document.querySelector("#pagelink a.last");
                        p = parseInt(a.text.trim().replace(/\D/g, ''), 10);
                    }
                    catch (e) { }
                    try {
                        let liss = document.querySelectorAll(".right_main .sk_cont ul li");
                        for (let i = 1; i < liss.length; i++) {
                            let li = liss[i];
                            let b = {};
                            let info = li.querySelector("span.sp_2 a");
                            if(info===null) continue;
                            b.url = info.attributes.href;
                            b.titlett = info.text.trim();
                            b.title = translate.ToFirstUpper(translate.ChineseToHanVietForBrowser(b.titlett), true);
                            b.type = translate.ChineseToHanVietForBrowser(li.querySelector("span.sp_1").text.trim());
                            b.id = b.url.replace(/\D/g, '');
                            b.image ="http://www.69shu.com/files/article/image//"+(b.id.length>=5?b.id.substring(0,2):b.id.substring(0,1))+"/"+b.id+"/"+b.id+"s.jpg";
                            b.newchapdes = li.querySelector("span.sp_4").text.trim();
                            if (b.url.indexOf("http://") === -1) {
                                b.url = uurl + b.url;
                            }
                            b.authortt = li.querySelector("span.sp_3").text.trim();
                            b.author = translate.ToFirstUpper(translate.ChineseToHanVietForBrowser(b.authortt), false);
                            b.maxpage = p;
                            books.push(b);
                        }

                    } catch (e) {
                        alert(e.toString());
                    }
                }
                successCallBack(books);
            })
            .catch((error) => {
                alert(error.toString());
            });
    }
    getBook(params, successCallBack, failCallBack) {
        axios(params.url, {
            method: 'GET',
            responseType: 'arraybuffer'
        }).then((response) => response.data)
            .then((responseJson) => {
                responseJson=iconv.decode(new Buffer(responseJson,'gbk'), 'gbk');                
                let doc = HTMLParser.parse(responseJson);
                let uurl = bookfile.ww.uurl + "/";
                //
                if (params.title === "") {
                    params.titlett = doc.querySelector(".zhbook_info .status h1 a").removeWhitespace().text.trim();
                    params.title = translate.ToFirstUpper(translate.ChineseToHanVietForBrowser(params.titlett), true);
                    params.authortt = doc.querySelector(".zhbook_info p.author a").removeWhitespace().text.trim();
                    params.author = translate.ChineseToHanVietForBrowser(params.authortt);

                    params.image = doc.querySelector(".zhbook_info .imgbox img").attributes.src;
                    if (params.image.indexOf("//") === 0) {
                        params.image = "https:" + params.image;
                    }
                    else if (params.image.indexOf("http://") === -1) {
                        params.image = uurl + params.image;
                    }
                }
                //
                let des = doc.querySelector("div.jianjie p");
                if (des != null) {
                    params.destt = des.structuredText.replace(/(?:\r\n|\r|\n)/g, '<br />');
                    params.des = translate.ChineseToVietPhraseOneMeaningForBrowser(params.destt);
                }
                if (params.Chaps == null) {
                    params.Chaps = [];
                }
                let curl = params.url.replace("txt/", "").replace(".htm", "");
                //
                axios(curl, {
                    method: 'GET',
                    responseType: 'arraybuffer'
                }).then((r) => r.data)
                    .then((rs) => {
                        rs=iconv.decode(new Buffer(rs,'gbk'), 'gbk');                        
                        doc = HTMLParser.parse(rs);
                        let achap = doc.querySelectorAll("div.mu_contain ul.mulu_list li a");
                        let len = achap.length;
                        for (let i = 0; i < len; i++) {
                            let a = achap[i];
                            let c = {};
                            c.url = a.attributes.href;
                            if (c.url.indexOf("http://") === -1) {
                                c.url = uurl + c.url;
                            }
                            c.namett = a.text.trim();
                            c.name = translate.ChineseToVietPhraseOneMeaningForBrowser(c.namett);
                            c.order = (len - i);
                            params.Chaps.push(c);
                        }
                        successCallBack(params);
                    }).catch((error) => {
                        alert(error.toString());
                        failCallBack(error);
                    });

            })
            .catch((error) => {
                alert(error.toString());
                failCallBack(error);
            });
    }
    getChap(params, successCallBack, failCallBack) {
        axios(params.url, {
            method: 'GET',
            responseType: 'arraybuffer'
        }).then((response) => response.data)
            .then((responseJson) => {
                responseJson=iconv.decode(new Buffer(responseJson,'gbk'), 'gbk');
                let document = HTMLParser.parse(responseJson);
                var doc = document.querySelector("div.yd_text2");
                params.contenttt = doc.structuredText.replace(/(?:\r\n|\r|\n)/g, '<br />');
                params.content = translate.ChineseToVietPhraseOneMeaningForBrowser(params.contenttt);
                successCallBack(params);
            })
            .catch((error) => {
                failCallBack(error);
            });
    }
    SearchBooks(q, successCallBack, failCallBack) {
        let books = [];
        let url = "http://zhannei.baidu.com/cse/site?q=" + q + "&cc=" + bookfile.ww.alias;
        axios(url, {
            method: 'GET'
        }).then((response) => response.data)
            .then((responseJson) => {
                if (responseJson != null) {
                    let document = HTMLParser.parse(responseJson);
                    try {
                        let liss = document.querySelectorAll("#results div.result");
                        for (let i = 0; i < liss.length; i++) {
                            let li = liss[i];
                            let b = {};
                            b.url = li.querySelector(".c-title a").attributes.href;
                            b.titlett = li.querySelector(".c-title a").removeWhitespace().text.trim();
                            b.title = translate.ToFirstUpper(translate.ChineseToHanVietForBrowser(b.titlett), true);
                            b.id = b.url.replace(/\D/g, '');
                            b.newchapdes = "";
                            let img = li.querySelector(".c-image img");
                            if (img != null) {
                                b.image = img.attributes.src;
                            }
                            b.newchapdestt = li.querySelector(".c-abstract").text.trim().substring(0, 10);
                            b.newchapdes = translate.ChineseToVietPhraseOneMeaningForBrowser(b.newchapdestt);
                            b.maxpage = 1;
                            books.push(b);
                        }

                    } catch (e) {
                        alert(e.toString());
                    }
                }
                successCallBack(books);
            })
            .catch((error) => {
                failCallBack(error);
            });
    }
    runAnalyzer(params, successCallBack, failCallBack) {

    }
    transLate(data, successCallBack, failCallBack) {

    }
}
export default shu69;
import bookfile from '../utils/bookfile';
import translate from '../utils/translate';
import axios from 'axios';
//axios.defaults.headers.get['User-Agent'] = 'Mozilla/5.0 (Windows NT 6.1; WOW64; rv:33.0) Gecko/20120101 Firefox/33.0';
//axios.defaults.headers.post['User-Agent'] = 'Mozilla/5.0 (Windows NT 6.1; WOW64; rv:33.0) Gecko/20120101 Firefox/33.0';
var _ = require('lodash');
var HTMLParser = require('fast-html-parser');
var iconv = require('iconv-lite');
class shumilou {
    getListType = (web, successCallBack, failCallBack) => {
        axios.get(web.web,{ responseType: 'arraybuffer' })
            .then(function (response) {
                response.data=iconv.decode(new Buffer(response.data,'gbk'), 'gbk');
                let doc = HTMLParser.parse(response.data);
                let as = doc.querySelectorAll("body div.top li.showmu div div a");
                let ts = [];
                for (let i = 0; i < as.length; i++) {
                    let a = as[i];
                    if (a.text.trim() === '') { continue; }
                    let obj = { typename: translate.ToFirstUpper(translate.ChineseToHanVietForBrowser(a.removeWhitespace().text.trim())), url: a.attributes.href, order: i, maxpage: null };
                    if (obj.url !== null && obj.url.indexOf("http") === -1 && obj.url.indexOf("www") === -1) {
                        obj.url = web.web + "/" + obj.url;
                    }
                    ts.push(obj);
                }
                successCallBack(ts)
            })
            .catch(function (error) {
                failCallBack(error);
            });
    }
    ListBooks(params, successCallBack, failCallBack) {
        let url = params.url;
        let uurl = bookfile.ww.web + "/";
        if (url === "") { url = uurl; }
        let books = [];
        axios(url, {
            method: 'GET',
            responseType: 'arraybuffer'
        }).then((response) => response.data)
            .then((responseJson) => {
                if (responseJson != null) {
                    responseJson=iconv.decode(new Buffer(responseJson,'gbk'), 'gbk');
                    let document = HTMLParser.parse(responseJson);
                    try {
                        let liss = document.querySelectorAll("div.list ul li");
                        for (let i = 0; i < liss.length; i++) {
                            let li = liss[i];
                            let b = {};
                            let link = li.querySelector("a");
                            if (link == null) { continue; }
                            b.url = link.attributes.href;
                            b.titlett = link.removeWhitespace().text.trim();
                            b.title = translate.ToFirstUpper(translate.ChineseToHanVietForBrowser(b.titlett), true);
                            b.type = translate.ChineseToHanVietForBrowser(params.typename);
                            b.id = b.url.replace(/\D/g, '');
                            b.newchapdes = " ";
                            if (b.url.indexOf("http://") === -1) {
                                b.url = uurl + b.url;
                            } else if (bookfile.ww.rurl) {
                                b.url = b.url.replace("m.", "www.");
                            }
                            b.authortt = li.removeWhitespace().text.trim().replace(b.titlett + "/", "");
                            b.author = translate.ToFirstUpper(translate.ChineseToHanVietForBrowser(b.authortt), false);
                            b.maxpage = 1;
                            books.push(b);
                        }

                    } catch (e) {
                        alert(e.toString());
                    }
                }
                successCallBack(books);
            })
            .catch((error) => {
                alert(error.toString());
            });
    }
    getBook(params, successCallBack, failCallBack) {
        axios(params.url, {
            method: 'GET',
            responseType: 'arraybuffer'
        }).then((response) => response.data)
            .then((responseJson) => {
                responseJson=iconv.decode(new Buffer(responseJson,'gbk'), 'gbk');
                let doc = HTMLParser.parse(responseJson);
                let uurl = bookfile.ww.web + "/";
                params.titlett = doc.querySelector(".list .tit").removeWhitespace().text.trim();
                params.title = translate.ToFirstUpper(translate.ChineseToHanVietForBrowser(params.titlett), true);

                params.image = doc.querySelector(".list img.sayimg").attributes.src;
                if (params.image.indexOf("//") === 0) {
                    params.image = "https:" + params.image;
                }
                else if (params.image.indexOf("http://") === -1) {
                    params.image = uurl + params.image;
                }
                if (params.Chaps == null) {
                    params.Chaps = [];
                }
                var achap = doc.querySelectorAll("body div.content div.list ul li.zl a");
                let len = achap.length;
                for (let i = 0; i < len; i++) {
                    let a = achap[i];
                    let c = {};
                    c.url = a.attributes.href;
                    if (c.url.indexOf("http://") === -1) {
                        c.url = params.url + c.url;
                    }
                    c.namett = a.text.trim();
                    c.name = translate.ChineseToVietPhraseOneMeaningForBrowser(c.namett);
                    c.order = i+1;
                    params.Chaps.push(c);
                }
                //params.Chaps;
                successCallBack(params);
            })
            .catch((error) => {
                alert(error.toString());
                failCallBack(error);
            });
    }
    getChap(params, successCallBack, failCallBack) {
        axios(params.url, {
            method: 'GET',
            responseType: 'arraybuffer'
        }).then((response) => response.data)
            .then((responseJson) => {
                responseJson=iconv.decode(new Buffer(responseJson,'gbk'), 'gbk');
                let document = HTMLParser.parse(responseJson);
                var doc = document.querySelector("#content p");
                var remove = doc.querySelectorAll("script,div,a,center");
                remove.forEach(e => {
                    doc.removeChild(e);
                });
                params.contenttt = doc.structuredText.replace(/(?:\r\n|\r|\n)/g, '<br />');
                params.content = translate.ChineseToVietPhraseOneMeaningForBrowser(params.contenttt);
                successCallBack(params);
            })
            .catch((error) => {
                failCallBack(error);
            });
    }
    SearchBooks(q, successCallBack, failCallBack) {
        let books = [];
        let url = "http://zhannei.baidu.com/cse/site?q="+q+"&cc="+bookfile.ww.alias;
        axios(url, {
            method: 'GET'
        }).then((response) => response.data)
            .then((responseJson) => {
                if (responseJson != null) {
                    let document = HTMLParser.parse(responseJson);
                    try {
                        let liss = document.querySelectorAll("#results div.result");
                        for (let i = 0; i < liss.length; i++) {
                            let li = liss[i];
                            let b = {};
                            b.url = li.querySelector(".c-title a").attributes.href;
                            b.titlett = li.querySelector(".c-title a").removeWhitespace().text.trim();
                            b.title = translate.ToFirstUpper(translate.ChineseToHanVietForBrowser(b.titlett), true);
                            b.id = b.url.replace(/\D/g, '');
                            b.newchapdes = "";
                            let img=li.querySelector(".c-image img");
                            if(img!=null){
                                b.image = img.attributes.src;
                            }
                            b.newchapdestt =li.querySelector(".c-abstract").text.trim().substring(0,10);
                            b.newchapdes = translate.ChineseToVietPhraseOneMeaningForBrowser(b.newchapdestt);
                            b.maxpage = 1;
                            books.push(b);
                        }

                    } catch (e) {
                        alert(e.toString());
                    }
                }
                successCallBack(books);
            })
            .catch((error) => {
                failCallBack(error);
            });
    }
    runAnalyzer(params, successCallBack, failCallBack) {

    }
    transLate(data, successCallBack, failCallBack) {

    }
}
export default shumilou;
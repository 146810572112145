import Dimen from './dimensionsUtil'
import calculateSize from 'calculate-size';
var _ = require('lodash');
const ONE_MINUTE = 60000;
const ONE_HOUR = 3600000;
const ONE_DAY = 86400000;
const ONE_WEEK = 604800000;

const ONE_SECOND_AGO = "giây trước";
const ONE_MINUTE_AGO = "phút trước";
const ONE_HOUR_AGO = "giờ trước";
const ONE_DAY_AGO = "ngày trước";
const ONE_MONTH_AGO = "tháng trước";
const ONE_YEAR_AGO = "năm trước";

let toSeconds = (date) => {
    return date / 1000
}

let toMinutes = (date) => {
    return toSeconds(date) / 60
}

let toHours = (date) => {
    return toMinutes(date) / 60
}

let toDays = (date) => {
    return toHours(date) / 24
}

let toMonths = (date) => {
    return toDays(date) / 30
}

let toYears = (date) => {
    return toMonths(date) / 365
}

let _getDescriptionTimeFromDate = (date) => {
    let delta = new Date().getTime() - date
    if (delta < 1 * ONE_MINUTE) {
        let seconds = parseInt(toSeconds(delta), 10)
        return (seconds <= 0 ? 1 : seconds) + ONE_SECOND_AGO;
    }
    if (delta < 45 * ONE_MINUTE) {
        let minutes = parseInt(toMinutes(delta), 10)
        return (minutes <= 0 ? 1 : minutes) + ONE_MINUTE_AGO;
    }
    if (delta < 24 * ONE_HOUR) {
        let hours = parseInt(toHours(delta), 10)
        return (hours <= 0 ? 1 : hours) + ONE_HOUR_AGO;
    }
    if (delta < 48 * ONE_HOUR) {
        return "Hôm qua";
    }
    if (delta < 30 * ONE_DAY) {
        let days = parseInt(toDays(delta), 10)
        return (days <= 0 ? 1 : days) + ONE_DAY_AGO;
    }
    if (delta < 12 * 4 * ONE_WEEK) {
        let months = parseInt(toMonths(delta), 10)
        return (months <= 0 ? 1 : months) + ONE_MONTH_AGO;
    } else {
        let years = parseInt(toYears(delta), 10)
        return (years <= 0 ? 1 : years) + ONE_YEAR_AGO
    }
}
export let getcurrentdateFormat = () => {
    let date = new Date();
    if (!date) {
        return ''
    } else {
        return _getDescriptionTimeFromDate(date)
    }
}
export let dateFormat = (date) => {
    if (!date) {
        return ''
    } else {
        let temp = date.replace('T', ' ').split('.')[0]
        return _getDescriptionTimeFromDate(parseDate(temp))
    }
}

export let wordCountFormat = (wordCount) => {
    if (wordCount / 10000 > 0) {
        return parseInt((wordCount / 10000) + 0.5, 10) + 'Từ';
    } else if (wordCount / 1000 > 0) {
        return parseInt((wordCount / 1000) + 0.5, 10) + 'Hàng ngàn từ';
    } else {
        return wordCount + 'Từ';
    }
}

export let timeFormat = () => {
    let temp = new Date()
    var h = temp.getHours()
    var minute = temp.getMinutes()
    minute = minute < 10 ? ('0' + minute) : minute
    return h + ':' + minute
}
export let dateFormatString = () => {
    let temp = new Date()
    var d = temp.getDate();
    d = d < 10 ? ('0' + d) : d;
    var m = temp.getMonth() + 1;
    m = m < 10 ? ('0' + m) : m;
    var y = temp.getFullYear();
    var h = temp.getHours()
    var minute = temp.getMinutes()
    minute = minute < 10 ? ('0' + minute) : minute
    return d + '/' + m + '/' + y + ' ' + h + ':' + minute
}

export let parseDate = (date) => {
    var isoExp, parts
    isoExp = /^\s*(\d{4})-(\d\d)-(\d\d)\s(\d\d):(\d\d):(\d\d)\s*$/
    try {
        parts = isoExp.exec(date)
    } catch (error) {
        return date
    }
    if (parts) {
        date = new Date(parts[1], parts[2] - 1, parts[3], parts[4], parts[5], parts[6]).getTime()
    } else {
        return date
    }
    return date
}
let fragmentText = (text, maxWidth,font) => {
    let rows = text.split("@");
    let lines = [];
    rows.forEach(r => {
        lines = lines.concat(renderLines(r, maxWidth,font));
    });
    return lines;
}
let get_tex_size = (txt, font) => {
    let element = document.createElement('canvas');
    let context = element.getContext("2d");
    context.font = font;
    var tsize = { 'width': context.measureText(txt).width, 'height': parseInt(context.font) };
    return tsize;
}
let getWidth = (txt, font) => {
    return get_tex_size(txt, font).width;
}
let renderLines = (text, maxWidth,font) => {
    let emmeasure = getWidth("M",font);
    let spacemeasure = getWidth(" ",font);

    if (maxWidth < emmeasure) // To prevent weird looping anamolies farther on.
        throw "Can't fragment less than one character.";

    if (getWidth(text,font) < maxWidth) {
        return [text];
    }

    var words = text.split(' '),
        metawords = [],
        lines = [];

    // measure first.
    for (var w in words) {
        var word = words[w];
        var measure = getWidth(word,font);

        // Edge case - If the current word is too long for one line, break it into maximized pieces.
        if (measure > maxWidth) {
            // TODO - a divide and conquer method might be nicer.
            var edgewords = (function (word, maxWidth) {
                var wlen = word.length;
                if (wlen == 0) return [];
                if (wlen == 1) return [word];

                var awords = [], cword = "", cmeasure = 0, letters = [];

                // Measure each letter.
                for (var l = 0; l < wlen; l++)
                    letters.push({ "letter": word[l], "measure": getWidth(word[l],font) });

                // Assemble the letters into words of maximized length.
                for (var ml in letters) {
                    var metaletter = letters[ml];

                    if (cmeasure + metaletter.measure > maxWidth) {
                        awords.push({ "word": cword, "len": cword.length, "measure": cmeasure });
                        cword = "";
                        cmeasure = 0;
                    }

                    cword += metaletter.letter;
                    cmeasure += metaletter.measure;
                }
                // there will always be one more word to push.
                awords.push({ "word": cword, "len": cword.length, "measure": cmeasure });
                return awords;
            })(word, maxWidth);

            // could use metawords = metawords.concat(edgwords)
            for (var ew in edgewords)
                metawords.push(edgewords[ew]);
        }
        else {
            metawords.push({ "word": word, "len": word.length, "measure": measure });
        }
    }

    // build array of lines second.
    var cline = "";
    var cmeasure = 0;
    for (var mw in metawords) {
        var metaword = metawords[mw];
        // If current word doesn't fit on current line, push the current line and start a new one.
        // Unless (edge-case): this is a new line and the current word is one character.
        if ((cmeasure + metaword.measure > maxWidth) && cmeasure > 0 && metaword.len > 1) {
            lines.push(cline)
            cline = "";
            cmeasure = 0;
        }

        cline += metaword.word;
        cmeasure += metaword.measure;

        // If there's room, append a space, else push the current line and start a new one.
        if (cmeasure + spacemeasure < maxWidth) {
            cline += " ";
            cmeasure += spacemeasure;
        } else {
            lines.push(cline)
            cline = "";
            cmeasure = 0;
        }
    }
    if (cmeasure > 0)
        lines.push(cline);
    return lines;
}
export let contentFormat = (content, w, h,p,font) => {
    let rh = get_tex_size("M",font).height+p;
    let maxLines = parseInt(h / rh);
    return _.chunk(fragmentText(content, w,font), maxLines);
}

export let contentFormatFull = (content) => {
    const length = content.length
    var array = []
    let x = 0, y, m = 0
    while (x < length) {
        let _array = []
        for (let i = 0; i <= 16; i++) {
            let str_spa = content.substring(x, x + 1)
            let str_sto = content.substring(x, x + 18)
            const re = /^\s+$/
            if (str_sto.indexOf('"') != -1) {
                y = x + str_sto.indexOf('"') + 1
                _array[i] = content.substring(x, y)
                x = y
                continue
            }
            else if (str_sto.indexOf('.') != -1) {
                y = x + str_sto.indexOf('.') + 1
                if (re.exec(content.substring(y, y + 1))) {
                    y = x + str_sto.indexOf('.') + 1
                    _array[i] = content.substring(x, y)
                    x = y
                    continue
                }
                else {
                    if (str_sto.indexOf('!') != -1) {
                        y = x + str_sto.indexOf('!') + 1
                        _array[i] = content.substring(x, y)
                        x = y
                        continue
                    }
                    else {
                        y = x + 18
                        _array[i] = content.substring(x, y)
                        x = y
                        continue
                    }
                }
            }
            else if (str_sto.indexOf('!') != -1) {
                y = x + str_sto.indexOf('!') + 1
                if (re.exec(content.substring(y, y + 1))) {
                    y = x + str_sto.indexOf('!') + 1
                    _array[i] = content.substring(x, y)
                    x = y
                    continue
                }
                else {
                    y = x + 18
                    _array[i] = content.substring(x, y)
                    x = y
                    continue
                }
            }
            else if (str_sto.indexOf('?') != -1) {
                y = x + str_sto.indexOf('?') + 1
                if (re.exec(content.substring(y, y + 1))) {
                    y = x + str_sto.indexOf('?') + 1
                    _array[i] = content.substring(x, y)
                    x = y
                    continue
                }
                else {
                    y = x + 18
                    _array[i] = content.substring(x, y)
                    x = y
                    continue
                }
            }
            else if (re.exec(str_spa)) {
                y = x + 24
                if (content.substring(x, y).indexOf('.') != -1) {
                    y = x + content.substring(x, y).indexOf('.') + 1
                    _array[i] = content.substring(x, y)
                    x = y
                    continue
                }
                _array[i] = content.substring(x, y)
                x = y
                continue
            }
            else {
                y = x + 18
                _array[i] = content.substring(x, y)
                x = y
            }
        }
        array[m] = _array
        m++
    }
    return array
}

import bookfile from '../utils/bookfile';
import translate from '../utils/translate';
import axios from 'axios';
var HTMLParser = require('fast-html-parser');
var _ = require('lodash');
const apiType = 'Home/getListType';
const apiAnalyzer = 'Home/Analyzer';
const apiList = 'Home/ListBooks';
const apiBook = 'Home/getBooks';
const apiChap = 'Home/getChap';
const apiTrans = "VietPhrase/FlutterTranslate";//"VietPhrase/ApiTranslate";
const apiHV = "VietPhrase/TranslateHanViet";
class webapi {
    getListType(web, successCallBack, failCallBack) {
        // if (bookfile.op.api && bookfile.op.trans) {
        fetch(bookfile.apiweb + apiType, {
            timeout: bookfile.timeout,
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                web: web.web,
            })
        }).then((response) => response.json())
            .then((responseJson) => {
                successCallBack(responseJson);
            })
            .catch((error) => {
                failCallBack(error);
            });
        //}
    }
    ListBooks(params, successCallBack, failCallBack) {
        //if (bookfile.op.api && bookfile.op.trans) {
        fetch(bookfile.apiweb + apiList, {
            timeout: bookfile.timeout,
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                page: params.page,
                web: params.url,
                typename: params.typename
            })
        }).then((response) => response.json())
            .then((responseJson) => {
                successCallBack(responseJson);
            })
            .catch((error) => {
                failCallBack(error);
            });
        //}
    }
    getBook(params, successCallBack, failCallBack) {
        //if (bookfile.op.api && bookfile.op.trans) {
        fetch(bookfile.apiweb + apiBook, {
            timeout: bookfile.timeout,
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                b: params,
            })
        }).then((response) => response.json())
            .then((responseJson) => {
                successCallBack(responseJson);
            })
            .catch((error) => {
                failCallBack(error);
            });
        //}
    }
    getChap(params, successCallBack, failCallBack) {
        //if (bookfile.op.api && bookfile.op.trans) {
        fetch(bookfile.apiweb + apiChap, {
            timeout: bookfile.timeout,
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                c: params,
            })
        }).then((response) => response.json())
            .then((responseJson) => {
                successCallBack(responseJson);
            })
            .catch((error) => {
                failCallBack(error);
            });
        //}
    }
    SearchBooks(q, successCallBack, failCallBack) {
        let books = [];
        let url = "http://zhannei.baidu.com/cse/site?q=" + q + "&cc=" + bookfile.ww.alias;
        axios(url, {
            method: 'GET'
        }).then((response) => response.data)
            .then((responseJson) => {
                if (responseJson != null) {
                    let document = HTMLParser.parse(responseJson);
                    try {
                        let liss = document.querySelectorAll("#results div.result");
                        for (let i = 0; i < liss.length; i++) {
                            let li = liss[i];
                            let b = {};
                            b.url = li.querySelector(".c-title a").attributes.href;
                            b.titlett = li.querySelector(".c-title a").removeWhitespace().text.trim();
                            b.title = translate.ToFirstUpper(translate.ChineseToHanVietForBrowser(b.titlett), true);
                            b.id = b.url.replace(/\D/g, '');
                            b.newchapdes = "";
                            let img = li.querySelector(".c-image img");
                            if (img != null) {
                                b.image = img.attributes.src;
                            }
                            b.newchapdestt = li.querySelector(".c-abstract").text.trim().substring(0, 10);
                            b.newchapdes = translate.ChineseToVietPhraseOneMeaningForBrowser(b.newchapdestt);
                            b.maxpage = 1;
                            books.push(b);
                        }

                    } catch (e) {
                        alert(e.toString());
                    }
                }
                successCallBack(books);
            })
            .catch((error) => {
                failCallBack(error);
            });
    }
    runAnalyzer(params, successCallBack, failCallBack) {
        //if (bookfile.op.api && bookfile.op.trans) {
        fetch(bookfile.apiweb + apiAnalyzer, {
            timeout: bookfile.timeout,
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(params)
        }).then((response) => response.json())
            .then((responseJson) => {
                successCallBack(responseJson);
            })
            .catch((error) => {
                failCallBack(error);
            });
        //}
    }
    transLate(txt, successCallBack, failCallBack) {
        axios.post(bookfile.apiweb + "VietPhrase/FlutterTranslate", {
            "data": txt,
        })
            .then((responseJson) => {
                successCallBack(responseJson.data);
            })
            .catch((error) => {
                failCallBack(error);
            });
    }
    transLateHV(data, successCallBack, failCallBack) {
        fetch(bookfile.apiweb + apiHV, {
            timeout: bookfile.timeout,
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                data: data,
            })
        }).then((response) => response.text())
            .then((responseJson) => {
                successCallBack(responseJson);
            })
            .catch((error) => {
                failCallBack(error);
            });
    }
}
export default webapi;
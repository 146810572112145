import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SearchIcon from '@material-ui/icons/Search';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReactPaginate from 'react-paginate';
import Card from '@material-ui/core/Card';
import CardContent  from '@material-ui/core/CardContent';
import bookfile from '../utils/bookfile';
import scraper from '../utils/scraper';
import Badge from '@material-ui/core/Badge';
import { Row, Col } from 'react-grid-system';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { toast } from 'react-toastify';
var _ = require('lodash');
const styles = theme => ({
    flex: {
        flex: 1,
    },
    rectpag: {
        position: 'absolute', bottom: 20
    },
    badge: { position: 'absolute', left: 20, top: 20 },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    avatar: {
        margin: 10,
    },
    bigAvatar: {
        width: 96,
        height: 126,
        minWidth: 96,
        marginRight: 10
    },
});
const queryString = require('query-string');
class ListBookPage extends React.Component {
    constructor(props) {
        super(props);
        if(bookfile.ok!=false){
            bookfile.ok = true;
        }
        bookfile.params.page = 1;
        bookfile.params.maxpage = 1;
        var pars = queryString.parse(window.location.href.split("/list?")[1]);
        if (pars.url != null) {
            bookfile.setWeb(pars.url);
        }
        if (pars.page != null) {
            bookfile.params.page = pars.page;
        }
        this.state = {
            open: false,
            dopen: false,
            loading: false,
            apiweb: !bookfile.op.api,
            books: [],
            types: []
        };
    }
    componentDidMount() {
        if (!bookfile.hasTuDien && !bookfile.op.api) {
            bookfile.loadVietPhrase().then((ok) => {
                if (ok) {
                    toast.success("Đã tải xong dữ liệu!");
                } else {
                    toast.warning("Chưa có dữ liệu lưu trên máy, vui lòng tải từ điển bên dưới!");
                }
            });
        }
        this.setState({ loading: true });
        this.loadType();
    }
    handleMenu = event => {
        this.setState({ open: event.currentTarget });
    };
    loadType() {
        scraper.getListType(bookfile.ww,
            (data) => {
                this.setState({
                    types: data,
                }, function () {
                    if (data.length > 0) {
                        if (bookfile.ok) {
                            bookfile.params.url = data[0].url;
                            bookfile.params.typename = data[0].typename;
                            bookfile.ok = false;
                        }
                        if (bookfile.ww.all) {
                            this.listBooksAll();
                        } else {
                            this.listBooks();
                        }
                    }
                });
            },
            (error) => {
                this.setState({ loading: false });
            });
    }
    goType(t) {
        window.scrollTo(0, 0);
        this.setState({ open: false });
        bookfile.params.url = t.url;
        bookfile.params.typename = t.typename;
        bookfile.params.page = 1;
        bookfile.params.maxpage = 1
        if (bookfile.ww.all) {
            this.listBooksAll();
        } else {
            this.listBooks();
        }
    }
    goBook(b) {
        bookfile.setBook(b);
    }
    skiptakeBook() {
        if (bookfile.params.page < 1) { bookfile.params.page = 1; }
        let offset = (bookfile.params.page - 1) * 20;
        let data = _(this.state.BooksFilter).slice(offset).take(20).value();
        this.setState({ books: data, loading: false });
    }
    listBooks() {
        this.setState({ loading: true });
        scraper.ListBooks(bookfile.params,
            (data) => {
                if (bookfile.params.maxpage === 1 && data.length > 0) {
                    bookfile.params.maxpage = data[0].maxpage;
                }
                this.setState({
                    loading: false,
                    books: data
                });
            },
            (error) => {
                this.setState({ loading: false });
            });
    }
    listBooksAll() {
        this.setState({ loading: true });
        scraper.ListBooks(bookfile.params,
            (data) => {
                if (data.length > 20) {
                    bookfile.params.maxpage = Math.ceil(data.length / 20);
                    this.setState({ BooksFilter: data }, () => {
                        this.skiptakeBook();
                    });
                }

            },
            (error) => {
                this.setState({ loading: false });
            });
    }
    handleRequestClose = () => {
        this.setState({ open: false });
    };
    handlePageClick = (data) => {
        window.scrollTo(0, 0);
        let url = window.location.href;
        if (url.indexOf("page=") === -1) {
            if(url.indexOf("?")==-1){
                url = url + "?page=" + (bookfile.params.page + 1);
            }else{
                url = url + "&page=" + (bookfile.params.page + 1);
            }
        } else {
            url = url.replace("page=" + bookfile.params.page, "page=" + (data.selected + 1));
        }
        window.history.pushState({}, null, url);
        bookfile.params.page = data.selected + 1;
        if (bookfile.ww.all) {
            this.skiptakeBook();
        } else {
            this.listBooks();
        }
    };
    Search() {
        this.setState({ loading: true });
        scraper.SearchBooks(this.state.tentruyen,
            (data) => {
                this.setState({
                    loading: false,
                    dopen: false,
                    books: data
                }, function () {
                });
            },
            (error) => {
                this.setState({ loading: false, dopen: false });
            });
    }
    renderBookData() {
        const { classes } = this.props;
        return (
            <Row>
                {this.state.books.map((item, i) => (
                    <Col xs={12} sm={6} md={6} lg={4} xl={3} key={i} className="cbook">
                        <Card>
                            <CardContent style={{ textAlign: 'center', justifyContent: 'center', height: 120, position: 'relative' }} onClick={() => this.goBook(item)}>
                                <Link to={"/book?url=" + item.url} style={{ color: '#000', textDecoration: 'none', display: 'flex', overflow: 'hidden' }}>
                                    <img alt="" src={item.image || 'http://vietphrase.info/images/fengmian.jpg'} className={classes.bigAvatar} />
                                    <div style={{ textAlign: 'left' }}>
                                        <div style={{
                                            fontWeight: 'bold',
                                            fontSize: 16,
                                            marginBottom: 5
                                        }}>
                                            {item.title}
                                        </div>
                                        <div>{item.author}</div>
                                        <div className='nowrap'>{item.newchapdes.toString().trim()}</div>
                                        <Badge className={classes.badge} badgeContent={(bookfile.params.page - 1) * this.state.books.length + i + 1} color="secondary">{''}</Badge>
                                    </div>
                                </Link>
                            </CardContent>

                        </Card>
                    </Col>
                ))}
            </Row>

        );
    }
    render() {
        const { classes } = this.props;
        return (
            <div>
                <AppBar position="fixed">
                    <Toolbar>
                        <IconButton color="inherit" aria-label="Menu" className={classes.menuButton}>
                            <Link to={"/"} style={{ color: '#fff', textDecoration: 'none' }}><ArrowBackIcon /></Link>
                        </IconButton>
                        <Typography type="title" color="inherit" className={classes.flex} style={{ textAlign: 'center' }}>
                            {bookfile.ww.isKey}<span style={{ fontSize: 12, color: 'yellow', display: 'block' }}>{bookfile.params.typename} ({bookfile.params.page + "/" + bookfile.params.maxpage})</span>
                        </Typography>
                        <div style={{ position: 'relative' }}>
                            <IconButton color="inherit" onClick={() => this.setState({ dopen: true })}>
                                <SearchIcon />
                            </IconButton>
                            <IconButton
                                aria-owns={!this.state.open ? 'menu-appbar' : null}
                                aria-haspopup="true"
                                onClick={this.handleMenu}
                                color="inherit"
                            >
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                style={{ top: 50 }}
                                id="menu-appbar"
                                //anchorEl={open}
                                open={Boolean(this.state.open)}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                onClose={this.handleRequestClose}
                            >
                                {this.state.types.map((item, i) => (

                                    <MenuItem key={i} style={{ color: '#000', fontWeight: 'bold' }} onClick={() => this.goType(item)}>{item.typename}</MenuItem>
                                ))}
                            </Menu>  
                        </div>
                    </Toolbar>
                </AppBar>
                <div style={{ paddingTop: 80, paddingLeft: 15, paddingRight: 15, paddingBottom: 60 }}>
                    {this.renderBookData()}
                </div>
                <div className="rectpag">
                    {bookfile.params.maxpage > 1 &&
                        <ReactPaginate previousLabel={"<"}
                            nextLabel={">"}
                            breakLabel={""}
                            breakClassName={"break-me"}
                            pageCount={bookfile.params.maxpage}
                            initialPage={bookfile.params.page - 1}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={2}
                            onPageChange={this.handlePageClick}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"} />
                    }
                </div>
                <Dialog
                    title=""
                    open={this.state.loading}
                >
                    <div style={{ padding: 20, textAlign: 'center' }}><CircularProgress /><br /><span>Đang tải dữ liệu....</span></div>
                </Dialog>
                <Dialog open={this.state.dopen} onClose={() => this.setState({ dopen: false })}>
                    <DialogTitle>Tìm kiếm truyện</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Vui lòng nhập tên truyện cần tìm kiếm.
                            </DialogContentText>
                        <TextField
                            autoFocus
                            onChange={(event) => this.setState({ tentruyen: event.target.value })}
                            margin="dense"
                            id="tentruyen"
                            label="Tên truyện"
                            type="text"
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.setState({ dopen: false })} color="primary">
                            Thoát
                            </Button>
                        <Button onClick={this.Search.bind(this)} color="primary">
                            Tìm kiếm
                            </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
};
export default withStyles(styles, { withTheme: true })(ListBookPage);
import bookfile from '../utils/bookfile';
import translate from '../utils/translate';
import Analyzer from '../utils/Analyzer';
import axios from 'axios';
//axios.defaults.headers.get['User-Agent'] = 'Mozilla/5.0 (Windows NT 6.1; WOW64; rv:33.0) Gecko/20120101 Firefox/33.0';
//axios.defaults.headers.post['User-Agent'] = 'Mozilla/5.0 (Windows NT 6.1; WOW64; rv:33.0) Gecko/20120101 Firefox/33.0';
var _ = require('lodash');
var HTMLParser = require('fast-html-parser');
class uukanshu {
    getListType = (web, successCallBack, failCallBack) => {
        axios.get(web.web)
            .then(async function (response) {
                let html = response.data;
                if (bookfile.hasTuDien != true) {
                    try {
                        html = await bookfile.transLate(html);
                    } catch (err) {
                    }
                }
                let doc = HTMLParser.parse(html);
                let as = doc.querySelectorAll("div.nav ul li a");
                let ts = [];
                for (let i = 0; i < as.length; i++) {
                    let a = as[i];
                    if (i <= 2 || a.text.trim() === '') { continue; }
                    let obj = { typename: translate.ToFirstUpper(translate.ChineseToHanVietForBrowser(a.removeWhitespace().text.trim())), url: a.attributes.href, order: i, maxpage: null };
                    if (obj.url !== null && obj.url.indexOf("http") === -1 && obj.url.indexOf("www") === -1) {
                        obj.url = web.web + "/" + obj.url;
                    }
                    ts.push(obj);
                }
                successCallBack(ts)
            })
            .catch(function (error) {
                failCallBack(error);
            });
    }
    ListBooks(params, successCallBack, failCallBack) {
        let url = params.url;
        let uurl = bookfile.ww.web + "/";
        if (url === "") { url = uurl; }
        let books = [];
        let page = parseInt(params.page, 10);
        if (page > 1) {
            url = url.replace("-1.html", "-" + page + ".html");
        }
        axios(url, {
            method: 'GET'
        }).then((response) => response.data)
            .then(async (responseJson) => {
                if (responseJson != null) {
                    if (bookfile.hasTuDien != true) {
                        try {
                            responseJson = await bookfile.transLate(responseJson);
                        } catch (err) {
                        }
                    }
                    let document = HTMLParser.parse(responseJson);
                    let p = 0;
                    try {
                        let a = document.querySelector("#page").lastChild;
                        if (a.attributes.href) {
                            p = parseInt(a.attributes.href.replace(/\D/g, ''), 10);
                        }
                    }
                    catch (e) { }
                    try {
                        let liss = document.querySelectorAll("div.content.clearfix span.list-item");
                        for (let i = 0; i < liss.length; i++) {
                            let li = liss[i];
                            let b = {};
                            b.url = li.querySelector("div.book-info h3 a.title-link").attributes.href;
                            b.titlett = li.querySelector("div.book-info h3 a.title-link").removeWhitespace().text.trim();
                            b.title = translate.ToFirstUpper(translate.ChineseToHanVietForBrowser(b.titlett), true);
                            b.type = translate.ChineseToHanVietForBrowser(params.typename);
                            b.id = b.url.replace(/\D/g, '');
                            b.newchapdes = "";
                            b.image = li.querySelector("a.bookImg img").attributes.src;
                            if (b.image.indexOf("//") === 0) {
                                b.image = "https:" + b.image;
                            }
                            else if (b.image.indexOf("http://") === -1) {
                                b.image = uurl + b.image;
                            }
                            if (b.url.indexOf("http://") === -1) {
                                b.url = uurl + b.url;
                            } else if (bookfile.ww.rurl) {
                                b.url = b.url.replace("m.", "www.");
                            }
                            let mas = li.querySelectorAll("div.book-info dl dt.book-item");
                            if (mas.length > 1) {
                                b.authortt = mas[0].querySelector("a").text.trim();
                                b.author = translate.ToFirstUpper(translate.ChineseToHanVietForBrowser(b.authortt), false);
                            }
                            if (mas.length > 2) {
                                let nc = mas[1].querySelector("a").removeWhitespace();
                                if (nc != null) {
                                    b.newchapdestt = nc.text.trim();
                                    b.newchapdes = translate.ChineseToVietPhraseOneMeaningForBrowser(b.newchapdestt);
                                    b.newchapurl = nc.attributes.href;
                                    if (b.newchapurl !== null && b.newchapurl.indexOf("http://") === -1) {
                                        b.newchapurl = uurl + b.newchapurl;
                                    }

                                }
                            }
                            if (mas.length > 3) {
                                let ds = mas[2].removeWhitespace();
                                if (ds != null) {
                                    b.destt = ds.text;
                                    b.des = translate.ChineseToVietPhraseOneMeaningForBrowser(b.destt);
                                }
                            }
                            b.maxpage = p;
                            books.push(b);
                        }

                    } catch (e) {
                        alert(e.toString());
                    }
                }
                successCallBack(books);
            })
            .catch((error) => {
                alert(error.toString());
            });
    }
    getBook(params, successCallBack, failCallBack) {
        axios(params.url, {
            method: 'GET'
        }).then((response) => response.data)
            .then(async (responseJson) => {
                if (bookfile.hasTuDien != true) {
                    try {
                        responseJson = await bookfile.transLate(responseJson);
                    } catch (err) {
                    }
                }
                let doc = HTMLParser.parse(responseJson);
                let uurl = bookfile.ww.web + "/";
                //
                if (params.title === "") {
                    params.titlett = doc.querySelector(".jieshao_content h1 a").removeWhitespace().text.trim();
                    params.title = translate.ToFirstUpper(translate.ChineseToHanVietForBrowser(params.titlett), true);
                    params.authortt = doc.querySelector(".jieshao_content h2 a").removeWhitespace().text.trim();
                    params.author = translate.ChineseToHanVietForBrowser(params.authortt);

                    params.image = doc.querySelector(".jieshao-img a.bookImg img").attributes.src;
                    if (params.image.indexOf("//") === 0) {
                        params.image = "https:" + params.image;
                    }
                    else if (params.image.indexOf("http://") === -1) {
                        params.image = uurl + params.image;
                    }
                }
                //
                let des = doc.querySelector(".jieshao_content h3");
                if (des != null) {
                    params.destt = des.structuredText.replace(/(?:\r\n|\r|\n)/g, '<br />');
                    params.des = translate.ChineseToVietPhraseOneMeaningForBrowser(params.destt);
                }
                if (params.Chaps == null) {
                    params.Chaps = [];
                }
                var achap = doc.querySelectorAll("#chapterList li a");
                let len = achap.length;
                for (let i = 0; i < len; i++) {
                    let a = achap[i];
                    let c = {};
                    c.url = a.attributes.href;
                    if (c.url.indexOf("http://") === -1) {
                        c.url = uurl + c.url;
                    }
                    c.namett = a.text.trim();
                    c.name = translate.ChineseToVietPhraseOneMeaningForBrowser(c.namett);
                    c.order = (len - i);
                    params.Chaps.push(c);
                }
                params.Chaps.reverse();
                successCallBack(params);
            })
            .catch((error) => {
                failCallBack(error);
            });
    }
    getChap(params, successCallBack, failCallBack) {
        axios(params.url, {
            method: 'GET'
        }).then((response) => response.data)
            .then(async (responseJson) => {
                if (bookfile.hasTuDien != true) {
                    try {
                        responseJson = await bookfile.transLate(responseJson);
                    } catch (err) {
                    }
                }
                let document = HTMLParser.parse(responseJson);
                var doc = document.querySelector("#contentbox");
                var remove = doc.querySelectorAll("div.ad_content");
                try {
                    remove.forEach(e => {
                        doc.removeChild(e);
                    });
                } catch (e) { }
                params.contenttt = doc.structuredText.replace(/(?:\r\n|\r|\n)/g, '<br />');
                params.content = translate.ChineseToVietPhraseOneMeaningForBrowser(params.contenttt);
                successCallBack(params);
            })
            .catch((error) => {
                failCallBack(error);
            });
    }
    SearchBooks(q, successCallBack, failCallBack) {
        let books = [];
        let url = "http://zhannei.baidu.com/cse/site?q=" + q + "&cc=" + bookfile.ww.alias;
        axios(url, {
            method: 'GET'
        }).then((response) => response.data)
            .then(async (responseJson) => {
                if (responseJson != null) {
                    if (bookfile.hasTuDien != true) {
                        try {
                            responseJson = await bookfile.transLate(responseJson);
                        } catch (err) {
                        }
                    }
                    let document = HTMLParser.parse(responseJson);
                    try {
                        let liss = document.querySelectorAll("#results div.result");
                        for (let i = 0; i < liss.length; i++) {
                            let li = liss[i];
                            let b = {};
                            b.url = li.querySelector(".c-title a").attributes.href;
                            b.titlett = li.querySelector(".c-title a").removeWhitespace().text.trim();
                            b.title = translate.ToFirstUpper(translate.ChineseToHanVietForBrowser(b.titlett), true);
                            b.id = b.url.replace(/\D/g, '');
                            b.newchapdes = "";
                            let img = li.querySelector(".c-image img");
                            if (img != null) {
                                b.image = img.attributes.src;
                            }
                            b.newchapdestt = li.querySelector(".c-abstract").text.trim().substring(0, 10);
                            b.newchapdes = translate.ChineseToVietPhraseOneMeaningForBrowser(b.newchapdestt);
                            b.maxpage = 1;
                            books.push(b);
                        }

                    } catch (e) {
                        alert(e.toString());
                    }
                }
                successCallBack(books);
            })
            .catch((error) => {
                failCallBack(error);
            });
    }
    runAnalyzer(params, successCallBack, failCallBack) {
        let contenttt = "";
        let ok = false;
        let i = 0;
        let len = params.urls.length - 1;
        params.urls.forEach(u => {
            axios(u, {
                method: 'GET'
            }).then((response) => response.data)
                .then((responseJson) => {
                    let document = HTMLParser.parse(responseJson);
                    var doc = document.querySelector("#contentbox");
                    var remove = doc.querySelectorAll("div.ad_content");
                    remove.forEach(e => {
                        doc.removeChild(e);
                    });
                    contenttt += doc.text;
                    i++;
                    if (i === len) {
                        Analyzer.runAnalyzer(contenttt, params.m1, params.m2, params.m3, successCallBack, failCallBack);
                    }
                })
                .catch((error) => {
                    failCallBack(error);
                });
        });
    }
}
export default uukanshu;
import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SwipeableViews from 'react-swipeable-views';
import { Link,withRouter  } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import Dialog from '@material-ui/core/Dialog';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Card from '@material-ui/core/Card';
import CardContent  from '@material-ui/core/CardContent';
import bookfile from '../utils/bookfile';
import scraper from '../utils/scraper';
import HomeIcon from '@material-ui/icons/Home';
import WebIcon from '@material-ui/icons/Web';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import ListIcon from '@material-ui/icons/List';
import InfoIcon from '@material-ui/icons/Info';
import FileDownloadIcon from '@material-ui/icons/SaveAlt';
import BookIcon from '@material-ui/icons/Book';
import SaveIcon from '@material-ui/icons/Save';
import ReactPaginate from 'react-paginate';
import SearchInput from 'react-search-input'
import LinearProgress from '@material-ui/core/LinearProgress';
import { toast } from 'react-toastify';
var _ = require('lodash');
var fileDownload = require('react-file-download');
const queryString = require('query-string');
const styles = theme => ({
    flex: {
        flex: 1,
    },
    rectpag: {
        position: 'absolute', bottom: 20
    },
    bottom: { position: "fixed", width: '100%', bottom: 0 },
    badge: { position: 'absolute', left: 20, top: 20 },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    avatar: {
        margin: 10,
    },
    bigAvatar: {
        width: 96,
        height: 126,
        marginRight: 10,
        float: 'left'
    },
    rightIcon: {
        marginLeft: theme.spacing.unit,
    },
    button: {
        margin: theme.spacing.unit,
    },
});
function TabContainer({ children }) {
    return (
        <div> {children}</div>
    );
}
let v = 0;
let pa = 0;
class BookPage extends React.Component {
    constructor(props) {
        super(props);
        var pars = queryString.parse(window.location.href.split("/book?")[1]);
        if (pars.url != null) {
            if (bookfile.book == null || bookfile.book == undefined || bookfile.book.url == null) {
                bookfile.book = { title: "", url: pars.url };
            }
        }
        this.state = {
            pz: 50,
            open: false,
            loading: false,
            apiweb: !bookfile.op.api,
            book: bookfile.book,
            Chaps: [],
            ChapsFilter: [],
            value: v,
            total: 1,
            completed: 0,
            isdownload: false
        };
    }
    componentDidMount() {
        if (!bookfile.hasTuDien && !bookfile.op.api) {
            bookfile.loadVietPhrase().then((ok) => {
                if (ok) {
                    toast.success("Đã tải xong dữ liệu!");
                } else {
                    toast.warning("Chưa có dữ liệu lưu trên máy, vui lòng tải từ điển bên dưới!");
                }
            });
        }
        this.getBooks();
    }
    handleMenu = event => {
        this.setState({ open: event.currentTarget });
    };
    handleChange = (event, value) => {
        this.setState({ value });
        v = value;
        if (v === 2) {
            this.setState({ loading: true });
            let url = [];
            if (this.state.ChapsFilter.length > 0) {
                for (let i = 0; i < 100; i++) {
                    if (this.state.ChapsFilter[i] != null) {
                        url.push(this.state.ChapsFilter[i].url);
                    }
                }
            }
            this.filterName(url);
        }
    };
    filterName(url) {
        let p = { urls: url, m1: 5, m2: 2, m3: 100 };
        scraper.runAnalyzer(p, (e) => {
            console.log(e[3]);
            this.setState({ loading: false });
        }, (r) => {
            console.log(r);
            this.setState({ loading: false });
        })
    }
    handleChangeIndex = index => {
        v = index;
        this.setState({ value: index });
    };
    getBooks() {
        if (this.state.book.Chaps && this.state.book.Chaps.length > 0) {
            this.setState({
                loading: false,
                book: this.state.book,
                ChapsFilter: this.state.book.Chaps,
                total: Math.ceil(this.state.book.Chaps.length / this.state.pz)
            }, function () {
                this.skiptakeChap(pa + 1);
            });
        } else {
            this.setState({ loading: true });
            scraper.getBook(this.state.book,
                (data) => {
                    this.setState({
                        loading: false,
                        book: data,
                        ChapsFilter: data.Chaps,
                        total: Math.ceil(data.Chaps.length / this.state.pz)
                    }, function () {
                        bookfile.addbookMark(data);
                        this.skiptakeChap(1);
                    });
                },
                () => {
                    this.setState({ loading: false });
                });
        }
    }
    goChap(c, i) {
        if (c === undefined) {
            c = this.state.book.Chaps[0];
        }
        bookfile.setBook(this.state.book);
        bookfile.setChap(c);
        let b = this.state.book;
        b.chap = c;
        b.chapindex = i;
        bookfile.addbookMark(b);
    }
    skiptakeChap(p) {
        if (p < 1) { p = 1; }
        let offset = (p - 1) * this.state.pz;
        let data = _(this.state.ChapsFilter).slice(offset).take(this.state.pz).value();
        this.setState({ Chaps: data });
    }
    handlePageClick = (data) => {
        var elem = document.getElementById('listchap'); //chat container's ID where your messages stack up.
        elem.scrollTop = 0;
        pa = data.selected;
        this.skiptakeChap(pa + 1);
    };
    handleRequestClose = () => {
        this.setState({ open: false });
    };
    searchUpdated(term) {
        var elem = document.getElementById('listchap'); //chat container's ID where your messages stack up.
        elem.scrollTop = 0;
        let c = _.filter(this.state.book.Chaps, function (o) { return o.name.indexOf(term) !== -1; });
        this.setState({ ChapsFilter: c, total: Math.ceil(c.length / 20) + 1 });
        pa = 0;
        this.skiptakeChap(1);
    }
    renderHTML(f) {
        let b = this.state.book;
        b.Chaps = this.state.ChapsFilter;
        let style = "<style>body{font-size:22px;font-family:tahoma;padding:40px;}.ch{color:blue;font-weight:bold}h1{font-size:20px;font-weight:bold;color:red;text-align:center}</style>";
        let strc = "<center><h2 style='color:red'>" + b.title + "</h2>";
        let strct = "<div id='ct'>";
        let fname = b.title;
        if (f) {
            strc += "<p><img src='" + b.image + "'></p>";
            strc += "<p><b>Tác giả</b>: " + b.author + "</p>";
            strc += "<p><b>Link</b>: " + b.url + "</p>";
            strc += "<p><i>Giới thiệu : " + b.des + "</i></p></center>";
            strc += "<div id='ml'>";
            for (let i = 0; i < b.Chaps.length; i++) {
                let obj = b.Chaps[i];
                strc += "<p><a class='ch' href='#c" + i + "'>" + obj.name + '</a></p>';
                strct += "<div class='ct' id='c" + i + "'><p><h1>" + obj.name + "</h1></p>" + obj.content + '</div>';
            }
            fname += "_VietPhrase.html";
        } else {
            strc += "<p><img src='" + b.image + "'></p>";
            strc += "<p><b>Tác giả</b>: " + b.authortt + "</p>";
            strc += "<p><b>Link</b>: " + b.url + "</p>";
            strc += "<p><i>Giới thiệu : " + b.destt + "</i></p></center>";
            strc += "<div id='ml'>";
            for (let i = 0; i < b.Chaps.length; i++) {
                let obj = b.Chaps[i];
                strc += "<p><a class='ch' href='#c" + i + "'>" + obj.namett + '</a></p>';
                strct += "<div class='ct' id='c" + i + "'><p><h1>" + obj.namett + "</h1></p>" + obj.contenttt + '</div>';
            }
            fname += "_Trung.html";
        }
        strc += '</div>';
        strct += '</div>';
        let strcontent = "<!DOCTYPE html><html><head><meta charset='utf-8'>" + style + "</head><body>" + strc + strct + "</body></html>";
        fileDownload(strcontent, fname);
    }
    Download() {
        if (!this.state.isdownload) {
            this.setState({ isdownload: true }, () => {
                let p = this.state.completed;
                let cs = _.filter(this.state.ChapsFilter, function (o) { return !o.isdownload; });
                for (let i = 0; i < cs.length; i++) {
                    let c = cs[i];
                    scraper.getChap(c,
                        (data) => {
                            c = data;
                            c.isdownload = true;
                            p++;
                            if ((p === cs.length)) {
                                this.setState({ isdownload: false, finish: true });
                            }
                            this.setState({
                                completed: p,
                                ChapsFilter: cs,
                                pro: Math.ceil(p * 100 / cs.length)
                            });
                        },
                        () => {
                            c.isdownload = false;
                        });
                }
            });
        } else {
            this.setState({ isdownload: false, finish: false, completed: 0, pro: 0 });
            this.renderHTML(false);
            this.renderHTML(true);
        }
    }
    renderChaps() {
        return (
            <div style={{ backgroundColor: '#fff' }}>
                {<SearchInput placeholder='Tìm kiếm' className="search-input" onChange={this.searchUpdated.bind(this)} />}
                <List id="listchap" style={{ height: 'calc(100vh - 203px)', backgroundColor: '#fff', overflowY: 'auto', padding: 0 }}>
                    {this.state.Chaps.map((item, i) => (
                        <ListItem button key={i} style={{ borderBottom: '1px solid #eee' }} onClick={() => this.goChap(item, i)}>
                            <Link to={"/chap?url=" + item.url} style={{ textDecoration: 'none', display: 'flex', overflow: 'hidden', width: '100%', color: bookfile.chap.url === item.url ? 'red' : 'black' }}>
                                <ListItemText primary={item.name} disableTypography={true} />
                            </Link>
                        </ListItem>
                    ))}
                </List>
                {this.state.total > 1 &&
                    <div className="bookpage" style={{ textAlign: 'center' }}>
                        <ReactPaginate previousLabel={"<"}
                            nextLabel={">"}
                            breakLabel={""}
                            breakClassName={"break-me"}
                            pageCount={this.state.total}
                            initialPage={pa}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={2}
                            onPageChange={this.handlePageClick}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"} />
                    </div>
                }
            </div>
        );
    }
    goBack() {
        this.props.history.goBack();
    }
    renderBookData() {
        const { classes } = this.props;
        return (
            <Card>
                {this.state.book &&
                    <CardContent className="cardBook" style={{ position: 'relative', minHeight: 'calc(100vh - 155px)' }}>
                        <img alt="" src={this.state.book.image} className={classes.bigAvatar} />
                        <div style={{ textAlign: 'left', lineHeight: '24px' }}>
                            <div style={{
                                fontWeight: 'bold',
                                fontSize: 16,
                                marginBottom: 5
                            }}>
                                {this.state.book.title != null ? this.state.book.title : ""}
                            </div>
                            <div>{this.state.book.author != null ? this.state.book.author : ""}</div>
                            <div>{(this.state.book.newchapdes != null ? this.state.book.newchapdes : "" + "").toString().trim()}</div>
                            <div>
                                <Link onClick={() => this.goChap()} to={"/chap"} style={{ color: '#fff', textDecoration: 'none' }}>
                                    <Button className={classes.button} variant="contained" color="primary">
                                        <BookIcon />
                                        Đọc ngay
                                    </Button>
                                </Link>
                            </div>
                            {this.state.isdownload && <div><i>Đang tải ... </i>(<b>{this.state.completed}</b>/<b style={{ color: 'red' }}>{this.state.ChapsFilter.length}</b>)</div>}
                            {this.state.finish &&
                                <div>
                                    <Button onClick={() => { this.renderHTML(false) }} variant="contained" dense><SaveIcon />File tiếng trung</Button>&nbsp;&nbsp;
                                    <Button onClick={() => { this.renderHTML(true) }} variant="contained" dense><SaveIcon />File tiếng việt</Button>
                                </div>
                            }
                        </div>
                        <div style={{ clear: 'both' }}></div>
                        <div style={{ marginTop: 5 }}>{this.state.isdownload && <LinearProgress mode="determinate" value={this.state.pro} />}</div>
                        <p style={{ lineHeight: '24px' }} dangerouslySetInnerHTML={{ __html: (this.state.book.des) }}></p>
                    </CardContent>
                }

            </Card>
        );
    }
    render() {
        const { classes } = this.props;
        return (
            <div>
                <AppBar position="fixed">
                    <Toolbar>
                        <IconButton color="inherit" aria-label="Menu" className={classes.menuButton} onClick={() => this.goBack()}>
                            <ArrowBackIcon />
                        </IconButton>
                        <Typography type="title" color="inherit" className={classes.flex} style={{ textAlign: 'center', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            {this.state.book.title}
                        </Typography>
                        <IconButton color="inherit">
                            <Link to={"/"} style={{ color: '#fff', textDecoration: 'none' }}><HomeIcon /></Link>
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <div style={{ paddingTop: 55 }}>
                    <SwipeableViews
                        axis={'x'}
                        index={this.state.value}
                        onChangeIndex={this.handleChangeIndex}
                        className="bookinfo"
                    >
                        <TabContainer>
                            {this.renderBookData()}
                            <Fab color={this.state.isdownload ? 'primary' : 'secondary'} aria-label="Tải về" style={{ position: 'fixed', bottom: 40, right: 20 }} onClick={() => this.Download()}>
                                {this.state.isdownload ? <SaveIcon /> : <FileDownloadIcon />}
                            </Fab>
                        </TabContainer>
                        <TabContainer>{this.renderChaps()}</TabContainer>
                    </SwipeableViews>
                </div>
                <Dialog
                    title=""
                    open={this.state.loading}
                >
                    <div style={{ padding: 20, textAlign: 'center'}}><CircularProgress /><br /><span>Đang tải dữ liệu....</span></div>
                </Dialog>
                <BottomNavigation
                    value={this.state.value}
                    onChange={this.handleChange}
                    showLabels
                    className={classes.bottom}
                >
                    <BottomNavigationAction label="Thông tin" icon={<InfoIcon />} />
                    <BottomNavigationAction label="Mục lục" icon={<ListIcon />} />
                    <BottomNavigationAction label="Lọc Name" icon={<WebIcon />} />
                    <BottomNavigationAction label="Theo dõi" icon={<StarBorderIcon />} />
                </BottomNavigation>
            </div>
        );
    }
};
export default withStyles(styles, { withTheme: true })(withRouter(BookPage));
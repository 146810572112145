class CharRange {
     startIndex
     length
     constructor (startIndex,length) {
        this.startIndex = startIndex;
        this.length = length;
    }
    GetEndIndex(){
        return this.startIndex + this.length - 1;
    }
    IsInRange(index)
    {
        if (this.startIndex > index)
        {
            return false;
        }
        return index <= this.startIndex + this.length - 1;
    }
}
export default CharRange;
import bookfile from '../utils/bookfile';
import translate from '../utils/translate';
import axios from 'axios';
//axios.defaults.headers.get['User-Agent'] = 'Mozilla/5.0 (Windows NT 6.1; WOW64; rv:33.0) Gecko/20120101 Firefox/33.0';
//axios.defaults.headers.post['User-Agent'] = 'Mozilla/5.0 (Windows NT 6.1; WOW64; rv:33.0) Gecko/20120101 Firefox/33.0';
// axios.defaults.responseType="arraybuffer";
// axios.interceptors.response.use(function (response) {
//     var ctype = response.headers["content-type"];
//     response.data = ctype.toLowerCase().indexOf('gb2312')>-1 ?
//         iconv.decode(new Buffer(response.data,'gb2312'), 'gb2312'):
//         (ctype.toLowerCase().indexOf('gbk')>-1?iconv.decode(new Buffer(response.data,'gbk'), 'gbk'):iconv.decode(new Buffer(response.data,'utf-8'), 'utf-8'));
//     return response;
//   })
var _ = require('lodash');
var HTMLParser = require('fast-html-parser');
var iconv = require('iconv-lite');
class pbtxt {
    getListType = (web, successCallBack, failCallBack) => {
        axios.get(web.web)
            .then(function (response) {
                let doc = HTMLParser.parse(response.data);
                let as = doc.querySelectorAll("body div.menu_nav ul li a");
                let ts = [];
                for (let i = 0; i < as.length; i++) {
                    if (i <= 1) {
                        i++;
                        continue;
                    }
                    let a = as[i];
                    let obj = { typename: translate.ToFirstUpper(translate.ChineseToHanVietForBrowser(a.removeWhitespace().text.trim())), url: a.attributes.href, order: i, maxpage: null };
                    if (obj.url !== null && obj.url.indexOf("http") === -1 && obj.url.indexOf("www") === -1) {
                        obj.url = web.web + obj.url;
                    }
                    ts.push(obj);
                }
                successCallBack(ts)
            })
            .catch(function (error) {
                failCallBack(error);
            });
    }
    ListBooks(params, successCallBack, failCallBack) {
        let url = params.url;
        let uurl = bookfile.ww.web;
        if (url === "") { url = uurl; }
        let books = [];
        let page = parseInt(params.page, 10);
        if (page > 1) {
            url = url + page + ".html";
        }
        axios(url, {
            method: 'GET'
        }).then((response) => response.data)
            .then((responseJson) => {
                if (responseJson != null) {
                    let document = HTMLParser.parse(responseJson);
                    let p = 0;
                    try {
                        let a = document.querySelector("div.list_page .pages label span");
                        p = parseInt(a.text.trim().replace(/\D/g, ''), 10);
                    }
                    catch (e) { }
                    try {
                        let liss = document.querySelectorAll("div.books .book");
                        for (let i = 0; i < liss.length; i++) {
                            let li = liss[i];
                            let b = {};
                            b.url = li.querySelector("div.title h2 a").attributes.href;
                            b.titlett = li.querySelector("div.title h2 a").removeWhitespace().text.trim().replace("《", "").replace("》最新章节", "");
                            b.title = translate.ToFirstUpper(translate.ChineseToHanVietForBrowser(b.titlett), true);
                            b.type = translate.ChineseToHanVietForBrowser(params.typename);
                            b.id = b.url.replace(/\D/g, '');
                            b.newchapdes = "";
                            b.image = li.querySelector("a img").attributes.src;
                            if (b.image.indexOf("//") === 0) {
                                b.image = "https:" + b.image;
                            }
                            else if (b.image.indexOf("http://") === -1) {
                                b.image = uurl + b.image;
                            }
                            if (b.url.indexOf("http://") === -1) {
                                b.url = uurl + b.url;
                            } else if (bookfile.ww.rurl) {
                                b.url = b.url.replace("m.", "www.");
                            }
                            b.authortt = li.querySelector("div.title").querySelectorAll("a")[1].text.trim();
                            b.author = translate.ToFirstUpper(translate.ChineseToHanVietForBrowser(b.authortt), false);
                            b.newchapdestt = li.lastChild.text.trim();
                            b.newchapdes = translate.ChineseToVietPhraseOneMeaningForBrowser(b.newchapdestt);
                            b.maxpage = p;
                            books.push(b);
                        }

                    } catch (e) {
                        alert(e.toString());
                    }
                }
                successCallBack(books);
            })
            .catch((error) => {
                alert(error.toString());
            });
    }
    getBook(params, successCallBack, failCallBack) {
        let url = params.url.replace("book/", "").replace(".html", "/");
        axios(url, {
            method: 'GET'
        }).then((response) => response.data)
            .then((responseJson) => {
                let doc = HTMLParser.parse(responseJson);
                let uurl = bookfile.ww.web + "/";
                //
                if (params.title === "") {
                    params.titlett = doc.querySelector(".title h1").removeWhitespace().text.trim();
                    params.title = translate.ToFirstUpper(translate.ChineseToHanVietForBrowser(params.titlett), true);
                    params.authortt = doc.querySelector(".author").removeWhitespace().text.trim().replace("文/","");
                    params.author = translate.ChineseToHanVietForBrowser(params.authortt);

                    params.image = doc.querySelector(".cover img").attributes.src;
                    if (params.image.indexOf("//") === 0) {
                        params.image = "https:" + params.image;
                    }
                    else if (params.image.indexOf("http://") === -1) {
                        params.image = uurl + params.image;
                    }
                }
                //
                let des = doc.querySelector(".intro");
                if (des != null) {
                    params.destt = des.structuredText.replace(/(?:\r\n|\r|\n)/g, '<br />');
                    params.des = translate.ChineseToVietPhraseOneMeaningForBrowser(params.destt);
                }
                if (params.Chaps == null) {
                    params.Chaps = [];
                }
                var achap = doc.querySelectorAll("div.list dl dd a");
                let len = achap.length;
                for (let i = 0; i < len; i++) {
                    let a = achap[i];
                    let c = {};
                    c.url = a.attributes.href;
                    if (c.url.indexOf("http://") === -1) {
                        c.url = url + c.url;
                    }
                    c.namett = a.text.trim();
                    c.name = translate.ChineseToVietPhraseOneMeaningForBrowser(c.namett);
                    c.order = i+1;
                    params.Chaps.push(c);
                }
                successCallBack(params);
            })
            .catch((error) => {
                failCallBack(error);
            });
    }
    getChap(params, successCallBack, failCallBack) {
        axios(params.url, {
            method: 'GET',
            responseType: 'arraybuffer'
        }).then((response) => response.data)
            .then((responseJson) => {
                let document = HTMLParser.parse(iconv.decode(new Buffer(responseJson,'utf-8'), 'utf-8'));
                var cs= document.querySelector("head").firstChild.attributes.content.split("charset=")[1].toLowerCase();
                if(cs!='utf-8'){
                    document = HTMLParser.parse(iconv.decode(new Buffer(responseJson,cs),cs));
                }
                var doc = document.querySelector("#main .content");
                var remove = doc.querySelectorAll("div.con_show_l");
                remove.forEach(e => {
                    doc.removeChild(e);
                });
                params.contenttt = doc.structuredText.replace(/(?:\r\n|\r|\n)/g, '<br />');
                params.content = translate.ChineseToVietPhraseOneMeaningForBrowser(params.contenttt);
                successCallBack(params);
            })
            .catch((error) => {
                failCallBack(error);
            });
    }
    SearchBooks(q, successCallBack, failCallBack) {
        let books = [];
        let url = "http://zhannei.baidu.com/cse/site?q=" + q + "&cc=" + bookfile.ww.alias;
        axios(url, {
            method: 'GET'
        }).then((response) => response.data)
            .then((responseJson) => {
                if (responseJson != null) {
                    let document = HTMLParser.parse(responseJson);
                    try {
                        let liss = document.querySelectorAll("#results div.result");
                        for (let i = 0; i < liss.length; i++) {
                            let li = liss[i];
                            let b = {};
                            b.url = li.querySelector(".c-title a").attributes.href;
                            b.titlett = li.querySelector(".c-title a").removeWhitespace().text.trim();
                            b.title = translate.ToFirstUpper(translate.ChineseToHanVietForBrowser(b.titlett), true);
                            b.id = b.url.replace(/\D/g, '');
                            b.newchapdes = "";
                            let img = li.querySelector(".c-image img");
                            if (img != null) {
                                b.image = img.attributes.src;
                            }
                            b.newchapdestt = li.querySelector(".c-abstract").text.trim().substring(0, 10);
                            b.newchapdes = translate.ChineseToVietPhraseOneMeaningForBrowser(b.newchapdestt);
                            b.maxpage = 1;
                            books.push(b);
                        }

                    } catch (e) {
                        alert(e.toString());
                    }
                }
                successCallBack(books);
            })
            .catch((error) => {
                failCallBack(error);
            });
    }
    runAnalyzer(params, successCallBack, failCallBack) {

    }
    transLate(data, successCallBack, failCallBack) {

    }
}
export default pbtxt;
import translate from './translate';
import bookfile from '../utils/bookfile';
var StringBuilder = require('string-builder');
var toTitleCase = require('to-title-case')
let Analyzer = {}
let clauses;
let content;
let group;
let resultDictionary = {};
let unfilteredResultDictionary = {};
let notInVietPhraseAndNameCheckBox = true;
let prioritizedNameCheckBox = true;
let translationAlgorithmComboBox = 1;
let isNullOrEmpty = (value) => {
    return value == null || value === "";
}
let SearchAndAddToTheDictionary = (MINIMUM_OCCURANCES, groupLength, clauseIndex, charIndex) => {
    group = clauses[clauseIndex].substring(charIndex, charIndex + groupLength);
    if (!isNullOrEmpty(group)) {
        if (unfilteredResultDictionary.hasOwnProperty(group)) {
            if (MINIMUM_OCCURANCES - 1 > unfilteredResultDictionary[group]) {
                let item = unfilteredResultDictionary;
                let strs = item;
                let str = group;
                item[str] = strs[str] + 1;
            }
            else {
                delete unfilteredResultDictionary[group];
                resultDictionary[group] = MINIMUM_OCCURANCES;
            }
        }
        else if (!resultDictionary.hasOwnProperty(group)) {
            unfilteredResultDictionary[group] = 1;
        }
        else {
            let item1 = resultDictionary;
            let strs1 = item1;
            let str1 = group;
            item1[str1] = strs1[str1] + 1;
        }

    }
}
let ischinese = (str) => {
    return ContainsKey(str, 3);
}
let ContainsKey = (key, flag) => {
    switch (flag) {
        case 1://Name
            return bookfile.arrName.hasOwnProperty(key);
        case 2://VietPhrase
            return bookfile.arrVietPhrase.hasOwnProperty(key);
        case 3:///HanViet
            return bookfile.arrHanViet.hasOwnProperty(key);
        default:
            return false;
    }
}
let InterateAllGroupsOfAClause = (MINIMUM_OCCURANCES, groupLength, clauseIndex) => {
    for (let i = 0; i < clauses[clauseIndex].length - groupLength + 1; i++) {
        SearchAndAddToTheDictionary(MINIMUM_OCCURANCES, groupLength, clauseIndex, i);
    }
}
let chineseToHanViet = (chinese) => {
    return translate.ChineseToHanVietForBrowser(chinese).toString().trim();
}
let chineseToVietPhrase = (chinese) => {
    return translate.ChineseToVietPhraseOneMeaningForBrowser(chinese).toString().trim();
}
let refine = (doneList, key) => {
    let flag;
    let flag1;
    if (!translate.IsInVietPhrase(key)) {
        if (key.includes("了") || key.includes("个") || key.includes("一") || key.includes("的") || key.includes("是") || key.includes("么") || key.includes("没") || key.includes("和")) {
            flag1 = false;
        }
        else if (key.includes("我") || key.includes("他") || key.includes("你") || key.includes("们")) {
            flag1 = false;
        }
        else {
            flag1 = (key.includes("\t") ? false : !key.includes("\n"));
        }
        if (flag1) {
            for (let str in doneList) {
                if (str.includes(key)) {
                    flag = false;
                    return flag;
                }
            }
            doneList.push(key);
            flag = true;
        }
        else {
            flag = false;
        }
    }
    else {
        doneList.push(key);
        flag = false;
    }
    return flag;
}
let splitMulti=(str, tokens)=>{
    var tempChar = tokens[0]; // We can use the first token as a temporary join character
    for(var i = 1; i < tokens.length; i++){
        str = str.split(tokens[i]).join(tempChar);
    }
    str = str.split(tempChar);
    return str;
}
Analyzer.runAnalyzer = (ct, MAXIMUM_CONTINUOUS_WORDS, MINIMUM_CONTINUOUS_WORDS, MINIMUM_OCCURANCES,successCallBack, failCallBack) => {
    let key = [];
    content = ct;
    let spl = ['=', '＝', '\n', '\\', '*', ',', '.', '\"', '\'', '(', ')', '?', '!', ':', ';', '·', '[', '/', ']', '，', '。', '…', '“', '、', '，', '”', '（', '）', '-', '《', '》', '？', '！', ' ', '\u3000', '：', '※', '；', '\u0060', '—', '&'];
    clauses = splitMulti(content, spl);
    let length = clauses.length;
    let num = 0;
    let bds = [];
    for (let i = MINIMUM_CONTINUOUS_WORDS; i <= MAXIMUM_CONTINUOUS_WORDS; i++) {
        for (let j = 0; j < length - 160; j++) {
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            j++;
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, j);
            num = j;
        }
        for (let k = num + 1; k < length; k++) {
            InterateAllGroupsOfAClause(MINIMUM_OCCURANCES, i, k);
        }
    }
    let strs = [];
    let stringBuilder = [];
    let stringBuilder1 = [];
    let selectedIndex = translationAlgorithmComboBox;
    let checked = prioritizedNameCheckBox;
    for (var k in resultDictionary) {
        if (resultDictionary.hasOwnProperty(k)) {
            if (refine(strs, k)) {
                let key = k.concat("=", chineseToHanViet(k), "=", chineseToVietPhrase(k), "=", resultDictionary[k]);
                stringBuilder.push(key);
                stringBuilder1.push(k.concat("=", chineseToHanViet(k)));
            }
        }
    }
    bds.push(stringBuilder);//result_TheoĐộDài.txt
    bds.push(stringBuilder1);//result_TheoĐộDài_ViếtHoa.txt
    strs = [];
    stringBuilder =[];
    stringBuilder1 = [];
    let stringBuilder2 = [];
    for (var k in resultDictionary) {
        if (resultDictionary.hasOwnProperty(k)) {
            if (translate.IsInVietPhrase(k)) {
                stringBuilder2.push(k.concat("=", chineseToHanViet(k), "=", chineseToVietPhrase(k), "=", resultDictionary[k]));
            }
            else if (refine(strs, k)) {
                stringBuilder.push(k.concat("=", chineseToHanViet(k), "=", chineseToVietPhrase(k), "=", resultDictionary[k]));
                stringBuilder1.push(k.concat("=", toTitleCase(chineseToHanViet(k))));
            }
        }
    }
    bds.push(stringBuilder);//result_TheoTầnSuất.txt
    bds.push(stringBuilder1);//result_TheoTầnSuất_ViếtHoa.txt
    bds.push(stringBuilder2);//result_TheoTầnSuất_VietPhraseOnly.txt
    resultDictionary = {};
    unfilteredResultDictionary = {};
    successCallBack(bds);
}

export default Analyzer;
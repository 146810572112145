import CharRange from './CharRange';
import bookfile from '../utils/bookfile';
let translate = {}
var _ = require('lodash');
var StringBuilder = require('string-builder');
let LastTranslatedWord_VietPhraseOneMeaning = "";
let ischinese = (str) => {
    return ContainsKey(str, 3);
}
let IsInVietPhrase = (str) => {
    return ContainsKey(str, 2);
}
let classifyWordsIntoLatinAndChinese = (inputText) => {
    let strs = [];
    let stringBuilder = new StringBuilder();
    let flag = false;
    let str = inputText;
    for (let i = 0; i < str.length; i++) {
        let chr = str[i];
        if (!ischinese(chr)) {
            if (flag) {
                strs.push(stringBuilder.toString());
                stringBuilder = new StringBuilder();
                stringBuilder.append(chr);
            }
            else {
                stringBuilder.append(chr);
            }
            flag = false;
        }
        else {
            if (!flag) {
                strs.push(stringBuilder.toString());
                stringBuilder = new StringBuilder();
                stringBuilder.append(chr);
            }
            else {
                stringBuilder.append(chr);
            }
            flag = true;
        }
    }
    strs.push(stringBuilder.toString());
    return strs;
}
let isNullOrEmpty = (value) => {
    return value == null || value === "";
}
let indentAllLines = (text, insertBlankLine) => {
    let strArrays = text.split('\n');
    let stringBuilder = new StringBuilder();
    let strArrays1 = strArrays;
    for (let i = 0; i < strArrays1.length; i++) {
        let str = strArrays1[i];
        if (!isNullOrEmpty(str.trim())) {
            stringBuilder.append("\t".concat(str.trim())).append("\n").append((insertBlankLine ? "\n" : ""));
        }
    }
    return stringBuilder.toString();
}
let StandardizeInputForBrowser = (original) => {
    if (isNullOrEmpty(original)) {
        return "";
    }
    let simplified = original;
    let nULLSTRING = ["，", "。", "：", "“", "”", "‘", "’", "？", "！", "「", "」", "．", "、", "\u3000", "…"];
    let strArrays = nULLSTRING;
    let strArrays1 = [", ", ".", ": ", "\"", "\" ", "'", "' ", "?", "!", "\"", "\" ", ".", ", ", " ", "...", ""];
    let strArrays2 = strArrays1;
    for (let i = 0; i < strArrays.length; i++) {
        //simplified = simplified.replace(strArrays[i], strArrays2[i]);
        simplified = simplified.split(strArrays[i]).join(strArrays2[i]);
    }
    //simplified = simplified.replace("  ", " ").replace(" \r\n", "\n").replace(" \n", "\n");
    simplified = simplified.split("  ").join(" ");
    simplified = simplified.split(" \r\n").join("\n");
    simplified = simplified.split(" \n").join("\n");
    simplified = oNarrow(simplified);
    let length = simplified.length;
    let stringBuilder = new StringBuilder();
    for (let j = 0; j < length - 1; j++) {
        let chr = simplified[j];
        let chr1 = simplified[j + 1];
        if (ischinese(chr)) {
            if (ischinese(chr1) || chr1 === ',' || chr1 === '.' || chr1 === ':' || chr1 === ';' || chr1 === '"' || chr1 === '\'' || chr1 === '?' || chr1 === ' ' || chr1 === '!') {
                stringBuilder.append(chr);
            }
            else {
                stringBuilder.append(chr).append(" ");
            }
        }
        else if (chr === '\t' || chr === ' ' || chr === '"' || chr === '\'' || chr === '\n') {
            stringBuilder.append(chr);
        }
        else if (!ischinese(chr1)) {
            stringBuilder.append(chr);
        }
        else {
            stringBuilder.append(chr).append(" ");
        }
    }
    stringBuilder.append(simplified[length - 1]);
    simplified = indentAllLines(stringBuilder.toString(), false);   
    return simplified;
}
let toUpperCase = (text) => {
    if (isNullOrEmpty(text)) {
        return text;
    }
    text = text.trim();
    if(isNullOrEmpty(text)){
        return text;
    }
    if (!text.startsWith("[") || 2 > text.length) {
        return text[0].toUpperCase().concat((text.length <= 1 ? "" : text.substring(1)));
    }
    return "[".concat(text[1].toUpperCase().concat((text.length <= 2 ? "" : text.substring(2))));
}

let ContainsKey = (key, flag) => {
    switch (flag) {
        case 1://Name
            return bookfile.arrName.hasOwnProperty(key);
        case 2://VietPhrase
            return bookfile.arrVietPhrase.hasOwnProperty(key);
        case 3:///HanViet
            return bookfile.arrHanViet.hasOwnProperty(key);
        default:
            return false;
    }
}
let containsName = (chinese, startIndex, phraseLength) => {
    if (phraseLength < 2) {
        return false;
    }
    if (ContainsKey(chinese.substring(startIndex, phraseLength + startIndex), 1)) {
        return false;
    }
    let num = startIndex + phraseLength - 1;
    let num1 = 2;
    for (let i = startIndex + 1; i <= num; i++) {
        for (let j = 20; j >= num1; j--) {
            if (chinese.length >= i + j && ContainsKey(chinese.substring(i, j + i), 1)) {
                return true;
            }
        }
    }
    return false;
}
let isLongestPhraseInSentence = (chinese, startIndex, phraseLength, dictionary, translationAlgorithm) => {
    let num;
    if (phraseLength < 2) {
        return true;
    }
    if (translationAlgorithm === 0) {
        num = phraseLength;
    }
    else {
        num = (phraseLength < 3 ? 3 : phraseLength);
    }
    let num1 = num;
    let num2 = startIndex + phraseLength - 1;
    for (let i = startIndex + 1; i <= num2; i++) {
        for (let j = 20; j > num1; j--) {
            if (chinese.length >= i + j && (dictionary.hasOwnProperty(chinese.substring(i, j + i)))) {
                return false;
            }
        }
    }
    return true;
}
let appendTranslatedWord = (result, translatedText, LastTranslatedWord_VietPhraseOneMeaning) => {
    let num = 0;
    appendTranslatedWordA(result, translatedText, LastTranslatedWord_VietPhraseOneMeaning, num);
}
let appendTranslatedWordA = (result, translatedText, LastTranslatedWord_VietPhraseOneMeaning, startIndexOfNextTranslatedText) => {
    if (LastTranslatedWord_VietPhraseOneMeaning.endsWith("\n") || LastTranslatedWord_VietPhraseOneMeaning.endsWith("\t") || LastTranslatedWord_VietPhraseOneMeaning.endsWith(". ") || LastTranslatedWord_VietPhraseOneMeaning.endsWith("\"") || LastTranslatedWord_VietPhraseOneMeaning.endsWith("'") || LastTranslatedWord_VietPhraseOneMeaning.endsWith("? ") || LastTranslatedWord_VietPhraseOneMeaning.endsWith("! ") || LastTranslatedWord_VietPhraseOneMeaning.endsWith(".\" ") || LastTranslatedWord_VietPhraseOneMeaning.endsWith("?\" ") || LastTranslatedWord_VietPhraseOneMeaning.endsWith("!\" ") || LastTranslatedWord_VietPhraseOneMeaning.endsWith(": ")) {
        LastTranslatedWord_VietPhraseOneMeaning = toUpperCase(translatedText);
    }
    else if (LastTranslatedWord_VietPhraseOneMeaning.endsWith(" ") || LastTranslatedWord_VietPhraseOneMeaning.endsWith("(")) {
        LastTranslatedWord_VietPhraseOneMeaning = translatedText;
    }
    else {
        LastTranslatedWord_VietPhraseOneMeaning = " ".concat(translatedText);
    }
    if ((!translatedText || translatedText[0] === ',' || translatedText[0] === '.' || translatedText[0] === '?' || translatedText[0] === '!') && 0 < result.length && result[result.length - 1] === ' ') {
        result = result.splice(result.length - 1, 1);
        startIndexOfNextTranslatedText = startIndexOfNextTranslatedText - 1;
    }
    result.append(LastTranslatedWord_VietPhraseOneMeaning);
}
let nextCharIsChinese = (chinese, currentPhraseEndIndex) => {
    if (chinese.length - 1 <= currentPhraseEndIndex) {
        return false;
    }
    return ischinese(chinese[currentPhraseEndIndex + 1]);
}
let oNarrow = (str) => {
    let i;
    let chr;
    let length = str.length;
    for (i = 0; i < length; i++) {
        chr = str[i];
        if (chr >= '！' && chr <= '～') {
            break;
        }
    }
    if (i >= length) {
        return str;
    }
    let stringBuilder = new StringBuilder();
    for (i = 0; i < length; i++) {
        chr = str[i];
        if (chr < '！' || chr > '～') {
            stringBuilder.append(chr);
        }
        else {
            stringBuilder.append(String.fromCharCode(chr - 65281 + 33));
        }
    }
    return stringBuilder.toString();
}
let ChineseToHanViet = (chinese) => {
    if (chinese === ' ') {
        return "";
    }
    let hv = bookfile.arrHanViet.hasOwnProperty(chinese.toString());
    if (!hv) {
        hv = oNarrow(chinese);
    } else {
        hv = bookfile.arrHanViet[chinese.toString()]
    }
    return hv;
}
let ChineseToVietPhraseOneMeaning = (chinese, wrapType, translationAlgorithm, prioritizedName, chinesePhraseRanges, vietPhraseRanges) => {
    LastTranslatedWord_VietPhraseOneMeaning = "";
    let charRanges = [];
    let charRanges1 = [];
    let stringBuilder = new StringBuilder();
    let length = chinese.length - 1;
    let num = 0;
    while (num <= length) {
        let flag = false;
        for (let i = 20; i > 0; i--) {
            if (length + 1 >= num + i) {
                if (ContainsKey(chinese.substring(num, i + num), 2)) {
                    if ((!prioritizedName || !containsName(chinese, num, i)) && ((translationAlgorithm !== 0 && translationAlgorithm !== 2) || isLongestPhraseInSentence(chinese, num, i, bookfile.arrVietPhrase, translationAlgorithm) || (prioritizedName && ContainsKey(chinese.substring(num, i + num), 1)))) {
                        charRanges.push(new CharRange(num, i));
                        if (wrapType !== 0) {
                            appendTranslatedWord(stringBuilder, "[".concat(bookfile.arrVietPhrase[chinese.substring(num, i + num)], "]"), LastTranslatedWord_VietPhraseOneMeaning);
                            charRanges1.push(new CharRange(stringBuilder.toString().length - bookfile.arrVietPhrase[chinese.substring(num, i + num)].length - 2, bookfile.arrVietPhrase[chinese.substring(num, i + num)].length + 2));
                        }
                        else {
                            appendTranslatedWord(stringBuilder, bookfile.arrVietPhrase[chinese.substring(num, i + num)], LastTranslatedWord_VietPhraseOneMeaning);
                            charRanges1.push(new CharRange(stringBuilder.toString().length - bookfile.arrVietPhrase[chinese.substring(num, i + num)].length, bookfile.arrVietPhrase[chinese.substring(num, i + num)].length));
                        }
                        if (nextCharIsChinese(chinese, num + i - 1)) {
                            stringBuilder.append(" ");
                            LastTranslatedWord_VietPhraseOneMeaning = LastTranslatedWord_VietPhraseOneMeaning.concat(" ");
                        }
                        flag = true;
                        num = num + i;
                        break;
                    }
                }
            }
        }

        if (flag) {
            continue;
        }
        let length1 = stringBuilder.toString().length;
        let length2 = ChineseToHanViet(chinese[num]).length;
        charRanges.push(new CharRange(num, 1));
        if (ischinese(chinese[num])) {
            appendTranslatedWord(stringBuilder, (wrapType !== 1 ? "" : "[").concat(ChineseToHanViet(chinese[num]), (wrapType !== 1 ? "" : "]")), LastTranslatedWord_VietPhraseOneMeaning);
            if (nextCharIsChinese(chinese, num)) {
                stringBuilder.append(" ");
                LastTranslatedWord_VietPhraseOneMeaning = LastTranslatedWord_VietPhraseOneMeaning.concat(" ");
            }
            length2 = length2 + (wrapType !== 1 ? 0 : 2);
        }
        else if ((chinese[num] === '"' || chinese[num] === '\'') && !LastTranslatedWord_VietPhraseOneMeaning.endsWith(" ") && !LastTranslatedWord_VietPhraseOneMeaning.endsWith(".") && !LastTranslatedWord_VietPhraseOneMeaning.endsWith("?") && !LastTranslatedWord_VietPhraseOneMeaning.endsWith("!") && !LastTranslatedWord_VietPhraseOneMeaning.endsWith("\t") && num < chinese.length - 1 && chinese[num + 1] !== ' ' && chinese[num + 1] !== ',') {
            stringBuilder.push(" ");
            stringBuilder.push(chinese[num]);
            let lastTranslatedWordVietPhraseOneMeaning = LastTranslatedWord_VietPhraseOneMeaning;
            let chr = chinese[num];
            LastTranslatedWord_VietPhraseOneMeaning = lastTranslatedWordVietPhraseOneMeaning.concat(" ".concat(chr.toString()));
        }
        else {
            stringBuilder.push(chinese[num]);
            let str = LastTranslatedWord_VietPhraseOneMeaning;
            let chr1 = chinese[num];
            LastTranslatedWord_VietPhraseOneMeaning = str.concat(chr1.toString());
            length2 = 1;
        }
        charRanges1.push(new CharRange(length1, length2));
        num++;
    }
    chinesePhraseRanges = charRanges;
    vietPhraseRanges = charRanges1;
    LastTranslatedWord_VietPhraseOneMeaning = "";
    return stringBuilder.toString();
}
translate.ToFirstUpper = (s, flag) => {
    if (isNullOrEmpty(s))
        return s;
    s = s.replace("\t", "").replace("\n", "").trim();
    let result = "";
    //lấy danh sách các từ  
    if (flag) {
        let words = s.split(' ');
        for (let word of words) {
            // từ nào là các khoảng trắng thừa thì bỏ  
            if (word.trim() !== "") {
                if (word.length > 1)
                    result += word.substring(0, 1).toUpperCase() + word.substring(1).toLowerCase() + " ";
                else
                    result += word.toUpperCase() + " ";
            }

        }
    }
    else {
        if (s.length > 1)
            result += s.substring(0, 1).toUpperCase() + s.substring(1).toLowerCase() + " ";
        else
            result += s.toUpperCase() + " ";
    }
    return result.trim();
}
translate.ChineseToVietPhraseOneMeaningForBrowser = (chinese) => {
    let charRangeArray = [];
    let charRangeArray1 = [];
    let str = "";
    chinese = StandardizeInputForBrowser(chinese);
    let stringBuilder = new StringBuilder();
    let strArrays = classifyWordsIntoLatinAndChinese(chinese);
    let length = strArrays.length;
    for (let i = 0; i < length; i++) {
        let str1 = strArrays[i];
        if (!isNullOrEmpty(str1)) {
            if (!ischinese(str1[0])) {
                str = str1;
            }
            else {
                str = ChineseToVietPhraseOneMeaning(str1, 0, 1, true, charRangeArray, charRangeArray1);
                if (i === 0 || !strArrays[i - 1].endsWith(", ")) {
                    str = toUpperCase(str);
                }
            }
            stringBuilder.append(str);
        }
    }
    return stringBuilder.toString();

}
translate.ChineseToHanVietForBrowser = (chinese) => {
    let charRangeArray = [];
    if (isNullOrEmpty(chinese)) {
        return "";
    }
    let str="";
    chinese = StandardizeInputForBrowser(chinese);
    let stringBuilder = new StringBuilder();
    let strArrays = classifyWordsIntoLatinAndChinese(chinese);
    for (let i = 0; i < strArrays.length; i++) {
        let str1 = strArrays[i];
        if (!isNullOrEmpty(str1)) {
            if (!ischinese(str1[0])) {
                str = str1;
            }
            else {
                str = ChineseToHanVietBrowser(str1, charRangeArray);
            }
            stringBuilder.append(str);
        }
    }
    return stringBuilder.toString();
}
translate.IsInVietPhrase = (str) => {
    return ContainsKey(str, 2);
}
let ChineseToHanVietBrowser = (chinese) => {
    if (chinese == null) {
        return "";
    }
    let LastTranslatedWord_HanViet = "";
    let charRanges = [];
    let stringBuilder = new StringBuilder();
    let length = chinese.length;
    for (let i = 0; i < length - 1; i++) {
        let num = stringBuilder.toString().Length;
        let chr = chinese[i];
        let chr1 = chinese[i + 1];
        if (!ischinese(chr)) {
            stringBuilder.append(chr);
            LastTranslatedWord_HanViet = LastTranslatedWord_HanViet.concat(chr.toString());
            charRanges.push(new CharRange(num, 1));
        }
        else if (!ischinese(chr1)) {
            appendTranslatedWord(stringBuilder, ChineseToHanViet(chr), LastTranslatedWord_HanViet, num);
            charRanges.push(new CharRange(num, ChineseToHanViet(chr).Length));
        }
        else {
            appendTranslatedWord(stringBuilder, ChineseToHanViet(chr), LastTranslatedWord_HanViet, num);
            stringBuilder.append(" ");
            LastTranslatedWord_HanViet = LastTranslatedWord_HanViet.concat(" ");
            charRanges.push(new CharRange(num, ChineseToHanViet(chr).length));
        }
    }
    if (!ischinese(chinese[length - 1])) {
        stringBuilder.append(chinese[length - 1]);
        let lastTranslatedWordHanViet = LastTranslatedWord_HanViet;
        let chr2 = chinese[length - 1];
        LastTranslatedWord_HanViet = lastTranslatedWordHanViet.concat(chr2.toString());
        charRanges.push(new CharRange(stringBuilder.toString().length, 1));
    }
    else {
        appendTranslatedWord(stringBuilder, ChineseToHanViet(chinese[length - 1]), LastTranslatedWord_HanViet);
        charRanges.push(new CharRange(stringBuilder.toString().length, ChineseToHanViet(chinese[length - 1]).length));
    }
    //let chineseHanVietMappingArray = charRanges;
    LastTranslatedWord_HanViet = "";
    return stringBuilder.toString();
}
export default translate;
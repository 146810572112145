import bookfile from './bookfile';
import translate from '../utils/translate';
import Analyzer from '../utils/Analyzer';
import axios from 'axios';
var HTMLParser = require('fast-html-parser');
let scraper = {}
//Lấy về danh sách thể loại
scraper.transLate = async (data, successCallBack, failCallBack) => {
    await bookfile.webapi.transLate(data, successCallBack, failCallBack);
}
scraper.transLateHV = async (data, successCallBack, failCallBack) => {
    await bookfile.webapi.transLateHV(data, successCallBack, failCallBack);
}
scraper.getListType = async (web, successCallBack, failCallBack) => {
    await bookfile.webapi.getListType(web, successCallBack, failCallBack);
}
scraper.ListBooks = async (params, successCallBack, failCallBack) => {
    await bookfile.webapi.ListBooks(params, successCallBack, failCallBack);
}
scraper.getBook = async (params, successCallBack, failCallBack) => {
    await bookfile.webapi.getBook(params, successCallBack, failCallBack);
}
scraper.getChap = async (params, successCallBack, failCallBack) => {
    await bookfile.webapi.getChap(params, successCallBack, failCallBack);
}
scraper.runAnalyzerWeb = async (params, successCallBack, failCallBack) => {
    await Analyzer.runAnalyzer(params.p1,params.p2,params.p3,params.p4,successCallBack, failCallBack);
}
scraper.runAnalyzer = async (params, successCallBack, failCallBack) => {
    await bookfile.webapi.runAnalyzer(params, successCallBack, failCallBack);
}
scraper.SearchBooks = async (q, successCallBack, failCallBack) => {
    await bookfile.webapi.SearchBooks(q, successCallBack, failCallBack);
}
scraper.SearchAllBooks = async (q, successCallBack, failCallBack) => {
    let books = [];
    let url = "http://www.baidu.com/s?wd=" + q;
    axios(url, {
        method: 'GET'
    }).then((response) => response.data)
        .then((responseJson) => {
            if (responseJson != null) {
                let document = HTMLParser.parse(responseJson);
                try {
                    let liss = document.querySelectorAll("#content_left div.result");
                    for (let i = 0; i < liss.length; i++) {
                        let li = liss[i];
                        let b = {};
                        b.url = li.querySelector("h3.t a").attributes.href;
                        b.titlett = li.querySelector("h3.t a").removeWhitespace().text.trim();
                        b.title = translate.ToFirstUpper(translate.ChineseToHanVietForBrowser(b.titlett), true);
                        b.id = b.url.replace(/\D/g, '');
                        b.newchapdes = "";
                        let img = li.querySelector(".c-img img");
                        if (img != null) {
                            b.image = img.attributes.src;
                        }
                        b.authortt = li.querySelector("div.c-gap-bottom-small span").text.trim().replace("作者", "");
                        b.author = translate.ChineseToVietPhraseOneMeaningForBrowser(b.authortt);

                        b.newchapdestt = li.querySelector(".c-abstract").text.trim().substring(0, 10);
                        b.newchapdes = translate.ChineseToVietPhraseOneMeaningForBrowser(b.newchapdestt);
                        b.maxpage = 1;
                        books.push(b);
                    }

                } catch (e) {
                    alert(e.toString());
                }
            }
            successCallBack(books);
        })
        .catch((error) => {
            failCallBack(error);
        });
}

export default scraper
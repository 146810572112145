import React from 'react';
import ReactDOM from 'react-dom';
import HomePage from './page/HomePage';
import ListBookPage from './page/ListBookPage';
import BookPage from './page/BookPage';
import WebPage from './page/WebPage';
import ChapPage from './page/ChapPage';
import index from './index.css'
import 'react-toastify/dist/ReactToastify.min.css'
import 'react-toastify/dist/ReactToastify.css';
import 'react-contexify/dist/ReactContexify.min.css';
import 'font-awesome/css/font-awesome.min.css';
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import bookfile from './utils/bookfile';
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';
const history = createBrowserHistory();
var mw = new Worker("worker.js");
ReactGA.initialize('UA-63208496-1');
history.listen((location, action) => {
    ReactGA.pageview(location.pathname + location.search);
});
const App = () => (
  <div>
    <Switch>
      <Route exact path='/' component={HomePage} />
      <Route path='/list/' component={ListBookPage} />
      <Route path='/book/' component={BookPage} />
      <Route path='/web' component={WebPage} />
      <Route path='/chap/' component={ChapPage} />
    </Switch>
    <ToastContainer
      position="top-right"
      type="default"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      pauseOnHover
    />
  </div>

);
if (!bookfile.op.isload) {
  mw.onmessage = (m) => {
    let d = m.data;
    switch (d.t) {
      case 0:
        toast.success("Đã tải xong dữ liệu !");
        bookfile.op.isload = true;
        break;
      case 1:
        bookfile.arrName = d.a;
        toast.success("Đã tải xong dữ liệu Name !");
        break;
      case 2:
        bookfile.arrHanViet = d.a;
        toast.success("Đã tải xong dữ liệu ChinesePhienAmWords!");
        break;
      case 3:
        bookfile.arrVietPhrase = d.a;
        toast.success("Đã tải xong dữ liệu VietPhrase!");
        break;
    }
  };
  //mw.postMessage(JSON.stringify({path:null,t:0}));
}
ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);
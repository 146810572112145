import { dateFormatString } from "./formatUtil";
//Scraper
import webapi from "../scraper/webapi";
import uukanshu from "../scraper/uukanshu";
import shumilou from "../scraper/shumilou";
import pbtxt from "../scraper/pbtxt";
import ranwen from "../scraper/ranwen";
import shu69 from "../scraper/shu69";
import Dexie from "dexie";
import axios from "axios";
var _ = require("lodash");
var mobile = require("is-mobile");
var db = new Dexie("VietPhraseDatabase");
const apiTrans = "VietPhrase/AppTranslate";
db.version(1).stores({
  booksMark:
    "url,id,title,*type,*newchapdes,*newchapurl,*image,*des,*maxpage,*Chaps,*chap,*chapindex,*Date,*cDate",
  booksStar:
    "url,id,title,*type,*newchapdes,*newchapurl,*image,*des,*maxpage,*Chaps,*chap,*chapindex,*Date,*cDate",
  booksDownload:
    "url,id,title,*type,*newchapdes,*newchapurl,*image,*des,*maxpage,*Chaps,*chap,*chapindex,*Date,*cDate",
  vietphrase: "++id,arrHanViet,arrName,arrVietPhrase"
});
axios.defaults.timeout = 30 * 1000;
class bookfile {
  static user = {};
  static logtype = -1;
  static value = 0;
  static value1 = 0;
  static value2 = 0;
  static value3 = 0;
  static arrHanViet = {};
  static arrName = {};
  static arrVietPhrase = {};
  //EndData
  static booksMark = [];
  static booksStar = [];
  static booksDownload = [];
  static op = {
    api: localStorage.getItem("apiweb") == "true" ? true : false,
    trans: true,
    isload: false
  };
  static ww = {};
  static book = {};
  static chap = {};
  static ismobile = mobile();
  static ok = false;
  static hasTuDien = false;
  static webapi = new webapi();
  static params = {
    url: "http://www.uukanshu.com//list/xianxia-1.html",
    typename: "Võ hiệp tiên hiệp",
    page: 1,
    maxpage: 1
  };
  static WebUrl = "";
  static apiweb = "http://qt.vietphrase.info/";
  static timeout = 30 * 1000;
  static webs = [];
  static cors = false;
  constructor() {}
  //Data
  static loadWebs(successCallBack, failCallBack) {
    axios
      .post(bookfile.apiweb + "Home/callProcnoToken", {
        proc: "ListSources",
        pas: null
      })
      .then(responseJson => {
        this.webs = JSON.parse(responseJson.data.data);
        successCallBack();
      })
      .catch(error => {
        failCallBack(error);
      });
  }
  static getUrl(op, successCallBack, failCallBack) {
    let url = op.url;
    let GB2312 = op.GB2312 || true;
    let t = op.t || "VP";
    let script = op.script || true;
    axios
      .get(
        bookfile.apiweb +
          "VietPhrase/Web?url=" +
          url +
          "&GB2312=" +
          GB2312 +
          "&script=" +
          script +
          "&t=" +
          t
      )
      .then(res => {
        successCallBack(res);
      })
      .catch(error => {
        failCallBack(error);
      });
  }
  static transLate = async data => {
    var vl = await fetch(bookfile.apiweb + apiTrans, {
      timeout: bookfile.timeout,
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        data: data,
        script: false
      })
    });
    var rp = await vl.text();
    return rp;
  };
  static saveVietPhrase() {
    db.transaction("rw", db.vietphrase, async () => {
      let vp = await db.vietphrase.get(0);
      let v = {
        id: 0,
        arrHanViet: this.arrHanViet,
        arrName: this.arrName,
        arrVietPhrase: this.arrVietPhrase
      };
      if (vp == null) {
        await db.vietphrase.add(v);
      } else {
        await db.vietphrase.put(v);
      }
    }).catch(e => {
      alert(e.stack || e);
    });
  }
  static loadVietPhrase() {
    this.hasTuDien = false;
    return db
      .transaction("rw", db.vietphrase, async () => {
        let vp = await db.vietphrase.get(0);
        if (vp != null) {
          this.arrName = vp.arrName;
          this.arrHanViet = vp.arrHanViet;
          this.arrVietPhrase = vp.arrVietPhrase;
          this.hasTuDien = true;
        }
      })
      .then(() => {
        return this.hasTuDien;
      })
      .catch(e => {
        alert(e.stack || e);
      });
  }
  static loadbooksMark() {
    db.transaction("rw", db.booksMark, async () => {
      this.booksMark = await db.booksMark
        .orderBy("cDate")
        .reverse()
        .toArray();
    }).catch(e => {
      alert(e.stack || e);
    });
  }
  static setWebUrl(u) {
    this.WebUrl = u;
  }
  static setBook(b) {
    this.book = b;
  }
  static setChap(c) {
    this.chap = c;
  }
  static setWeb(u) {
    if (this.webs[0] == null) {
      this.loadWebs(() => {
        this.setWeb(u);
      });
    } else {
      this.ww = _.find(this.webs[0], function(n) {
        return u.trim().indexOf(n.isKey) !== -1;
      });
      if (this.ww === undefined) {
        this.ww = _.find(this.webs[1], function(n) {
          return u.trim().indexOf(n.isKey) !== -1;
        });
      }
      if (!bookfile.op.api) {
        switch (this.ww.isKey) {
          case "uukanshu":
            this.webapi = new uukanshu();
            break;
          case "pbtxt.com":
            this.webapi = new pbtxt();
            break;
          case "shumilou.co":
            this.webapi = new shumilou();
            break;
          case "ranwen.org":
            this.webapi = new ranwen();
            break;
          case "69shu.com":
            this.webapi = new shu69();
            break;
          default:
            this.webapi = new webapi();
            break;
        }
      } else {
        this.webapi = new webapi();
      }
    }
  }
  static async addbookMark(b) {
    try {
      this.booksMark = this.booksMark.reverse();
      b.Date = dateFormatString();
      b.cDate = new Date();
      var chaps = b.Chaps;
      b.ChapsLength = chaps.length;
      //delete b.Chaps;
      var index = _.findIndex(this.booksMark, { url: b.url });
      if (index === -1) {
        this.booksMark.push(b);
      } else {
        this.booksMark.splice(index, 1, b);
      }
      db.transaction("rw", db.booksMark, async () => {
        if (index === -1) {
          await db.booksMark.add(b);
        } else {
          await db.booksMark.put(b);
        }
      }).catch(() => {});
    } catch (error) {}
  }
  static async delbookMark(b) {
    try {
      var index = _.findIndex(this.booksMark, { url: b.url });
      this.booksMark.splice(index, 1);
      db.transaction("rw", db.booksMark, async () => {
        await db.booksMark.delete(b.url);
      }).catch(e => {
        alert(e.stack || e);
      });
    } catch (error) {}
  }
  static setbooksMark(b) {
    this.booksMark = b;
  }
  static addbookStar(b) {
    b.Date = dateFormatString();
    var index = _.findIndex(this.booksStar, { url: b.url });
    if (index === -1) {
      this.booksStar.push(b);
    } else {
      this.booksStar.splice(index, 1, b);
    }
  }
  static setbookStar(b) {
    this.booksStar = b;
  }
}

export default bookfile;

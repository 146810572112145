import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import HomeIcon from '@material-ui/icons/Home';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import bookfile from '../utils/bookfile';
import scraper from '../utils/scraper';
import { contentFormat } from '../utils/formatUtil';
import TextFormatIcon from '@material-ui/icons/TextFormat';
import FileDownloadIcon from '@material-ui/icons/SaveAlt';
import ReactPaginate from 'react-paginate';
import SearchInput from 'react-search-input'
import Popover from '@material-ui/core/Popover';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { toast } from 'react-toastify';
import Fab from '@material-ui/core/Fab';
var _ = require('lodash');
const queryString = require('query-string');
const styles = theme => ({
    row: { flex: 1, margin: 5 },
    flex: {
        flex: 1,
    },
    name: { fontWeight: 'bold' },
    action: { textAlign: 'center' },
    rectpag: {
        position: 'absolute', bottom: 20
    },
    bottom: { position: "fixed", width: '100%', bottom: 0, backgroundColor: '#f4f4f4' },
    badge: { position: 'absolute', left: 20, top: 20 },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    avatar: {
        margin: 10,
    },
    bigAvatar: {
        width: 96,
        height: 126,
        marginRight: 10
    },
    button: {
        margin: theme.spacing.unit,
    },
    leftIcon: {
        marginRight: theme.spacing.unit,
    },
    rightIcon: {
        marginLeft: theme.spacing.unit,
    },
});
class ChapPage extends React.Component {
    constructor(props) {
        super(props);
        var pars = queryString.parse(window.location.href.split("/chap?")[1]);
        if (pars.url != null) {
            if (bookfile.chap == null || bookfile.chap == undefined || bookfile.chap.url == null) {
                bookfile.chap = { name: "", url: pars.url };
            }
        }
        this.state = {
            new: false,
            column: 2,
            open: false,
            loading: false,
            apiweb: !bookfile.op.api,
            book: bookfile.book,
            Chaps: [],
            Chap: bookfile.chap,
            ChapsFilter: [],
            value: 0,
            total: 1,
            showbar: false,
            c: 0,
            page: 0,
            len: 1,
            padding: 80,
            pmargin: 15,
            bg: '#fff',
            fs: bookfile.ismobile ? 18 : 26,
            fm: 'Arial'
        };
    }
    componentDidMount() {
        if (!bookfile.hasTuDien && !bookfile.op.api) {
            bookfile.loadVietPhrase().then((ok) => {
                if (ok) {
                    toast.success("Đã tải xong dữ liệu!");
                } else {
                    toast.warning("Chưa có dữ liệu lưu trên máy, vui lòng tải từ điển bên dưới!");
                }
            });
        }
        window.addEventListener('resize', this.resize.bind(this))
        let index = _.findIndex(bookfile.book.Chaps, { url: bookfile.chap.url });
        this.setState({ c: index });
        if (window.innerWidth <= 960) {
            this.setState({
                column: 1,
                padding: 40
            }, function () {
                this.getChap();
            });
        } else {
            this.setState({
                column: 2,
                padding: 80
            }, function () {
                this.getChap();
            });
        }
        this.divElement.focus();
    }
    handleMenu = event => {
        this.setState({ open: event.currentTarget });
    };
    handleChange = (event, value) => {
        this.setState({ value });
    };
    handleChangeIndex = index => {
        this.setState({ value: index });
    };
    changePage(p) {
        this.setState({ page: p }, () => {

        });
    }
    Prev = function () {
        if (this.state.page > 0) {
            this.changePage(this.state.page - 1);
        } else {
            if (this.state.c > 0) {
                let i = this.state.c - 1;
                this.setState({ page: -1, c: i, Chap: this.state.ChapsFilter[i] }, function () {
                    this.getChap(true);
                });
            }
        }

    }
    Next = function () {
        if (this.state.page < this.state.len - 1) {
            this.changePage(this.state.page + 1);
        } else {
            if (this.state.c < this.state.total - 1) {
                let i = this.state.c + 1;
                this.setState({ c: i, Chap: this.state.ChapsFilter[i], page: 0 }, function () {
                    this.getChap();
                });
            }
        }
    }
    handleKeyPress = (event) => {
        if (event.key == 'ArrowRight' || event.key == 'ArrowDown') {
            this.Next();
        } else if (event.key == 'ArrowLeft' || event.key == 'ArrowUp') {
            this.Prev();
        }
    }
    getBooks() {
        scraper.getBook(this.state.book,
            (data) => {
                let index = _.findIndex(data.Chaps, { url: bookfile.chap.url });
                let c = this.state.Chap;
                c.name = data.Chaps[index].name;
                this.setState({
                    c: index,
                    Chap: c,
                    new: true,
                    book: data,
                    ChapsFilter: data.Chaps,
                    total: Math.ceil(data.Chaps.length / 20)
                }, function () {
                    bookfile.setBook(this.state.book);
                    bookfile.setChap(this.state.Chap);
                    bookfile.addbookMark(data);
                    this.skiptakeChap(1);
                });
            },
            () => {
                //this.setState({ loading: false });
            });
    }
    getChap(b) {
        this.setState({ loading: true });
        scraper.getChap(this.state.Chap,
            (data) => {
                if (data != null) {
                    let _content = data.content.trim().replace(/\n/g, '').replace(/<br\s*\/?>/gi, '@');
                    //if (bookfile.op.api) {
                    _content = _content.replace(/<p[^>]*>/g, '').replace(/<\/p>/g, '@');
                    //}
                    let arrContents=(contentFormat(_content, (window.innerWidth - this.state.padding * (this.state.column === 1 ? 2 : 3)) / this.state.column, window.innerHeight - (this.state.padding * 2), this.state.pmargin, this.state.fs + "px " + this.state.fm));
                    data.arrContent = _.chunk(arrContents, this.state.column);
                    this.setState({
                        loading: false,
                        showbar: false,
                        Chap: data,
                        len: data.arrContent.length,
                        page: b ? data.arrContent.length - 1 : 0,
                        ChapsFilter: bookfile.book.Chaps,
                        total: bookfile.book.Chaps != undefined ? Math.ceil(bookfile.book.Chaps.length / 20) : 0
                    }, function () {
                        this.skiptakeChap(1);
                        if (bookfile.book.url == null) {
                            bookfile.book.url = data.bookurl;
                            this.getBooks();
                        }
                    });
                }
            },
            (error) => {
                this.setState({ loading: false });
            });
    }
    resize() {
        let data = this.state.Chap;
        if(!data.content) return;
        let _content = data.content.trim().replace(/\n/g, '').replace(/<br\s*\/?>/gi, '@');
        if (bookfile.op.api) {
            _content = _content.replace(/<p[^>]*>/g, '').replace(/<\/p>/g, '@');
        }
        if (window.innerWidth <= 960) {
            this.setState({
                column: 1,
                padding: 40
            }, function () {
                data.arrContent = _.chunk((contentFormat(_content, (window.innerWidth - this.state.padding * (this.state.column === 1 ? 2 : 3)) / this.state.column, window.innerHeight - (this.state.padding * 2), this.state.pmargin, this.state.fs + "px " + this.state.fm)), this.state.column);
                this.setState({
                    Chap: data,
                    len: data.arrContent.length,
                    page: parseInt(this.state.page * data.arrContent.length / this.state.len)
                })
            });
        } else {
            this.setState({
                column: 2,
                padding: 80
            }, function () {
                data.arrContent = _.chunk((contentFormat(_content, (window.innerWidth - this.state.padding * (this.state.column === 1 ? 2 : 3)) / this.state.column, window.innerHeight - (this.state.padding * 2), this.state.pmargin, this.state.fs + "px " + this.state.fm)), this.state.column);
                this.setState({
                    Chap: data,
                    len: data.arrContent.length,
                    page: parseInt(this.state.page * data.arrContent.length / this.state.len)
                })
            });
        }
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.resize)
    }
    Toogebar() {
        this.setState({ showbar: !this.state.showbar });
    }
    skiptakeChap(p) {
        let offset = (p - 1) * 20;
        let data = _(this.state.ChapsFilter).slice(offset).take(20).value();
        this.setState({ Chaps: data });
    }
    handlePageClick = (data) => {
        var elem = document.getElementById('listchap'); //chat container's ID where your messages stack up.
        elem.scrollTop = 0;
        this.skiptakeChap(data.selected + 1);
    };
    handleRequestClose = () => {
        this.setState({ open: false });
    };
    handleTouchTap = (event) => {
        // This prevents ghost click.
        event.preventDefault();

        this.setState({
            open: true,
            anchorEl: event.currentTarget,
        });
    };
    searchUpdated(term) {
        var elem = document.getElementById('listchap'); //chat container's ID where your messages stack up.
        elem.scrollTop = 0;
        let c = _.filter(this.state.book.Chaps, function (o) { return o.name.indexOf(term) !== -1; });
        this.setState({ ChapsFilter: c, total: Math.ceil(c.length / 20) + 1 });
        this.skiptakeChap(1);
    }
    renderChaps() {
        return (
            <div>
                <SearchInput className="search-input" onChange={this.searchUpdated.bind(this)} />
                <List id="listchap" style={{ height: 'calc(100vh - 232px)', backgroundColor: '#fff', overflow: 'auto', padding: 0 }}>
                    {this.state.Chaps.map((item, i) => (
                        <ListItem button key={i} style={{ borderBottom: '1px solid #eee' }}>
                            <ListItemText primary={item.name} />
                            <ListItemSecondaryAction>
                                <IconButton>
                                    <FileDownloadIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>
                {this.state.total > 1 &&
                    <div style={{ textAlign: 'center' }}>
                        <ReactPaginate previousLabel={"<"}
                            nextLabel={">"}
                            breakLabel={""}
                            breakClassName={"break-me"}
                            pageCount={this.state.total}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={2}
                            onPageChange={this.handlePageClick}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"} />
                    </div>
                }
            </div>
        );
    }
    renderBookData() {
        if (this.state.column === 1) {
            return (
                <div onClick={this.Toogebar.bind(this)}>
                    {
                        this.state.book && this.state.Chap &&
                        this.state.Chap.arrContent && this.state.Chap.arrContent[this.state.page].map((p, i) => {
                            return (
                                <div key={i}>
                                    {
                                        p.map((c, k) => {
                                            return (
                                                <p style={{ margin: "10px 0 0" }} key={k}>{c}</p>
                                            );
                                        })
                                    }
                                </div>
                            );
                        })
                    }
                </div>
            );
        }
        return (
            <div onClick={this.Toogebar.bind(this)} style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                {
                    this.state.book && this.state.Chap &&
                    this.state.Chap.arrContent && this.state.Chap.arrContent[this.state.page].map((p, i) => {
                        return (
                            <div key={i} style={{ marginLeft: i % 2 == 0 ? 0 : this.state.padding / 2, marginRight: i % 2 == 0 ? this.state.padding / 2 : 0, flexBasis: "calc(" + (100 / this.state.column) + "% - " + (this.state.padding / 2) + "px)" }}>
                                {
                                    p.map((c, k) => {
                                        return (
                                            <p style={{ margin: "10px 0 0" }} key={k}>{c}</p>
                                        );
                                    })
                                }
                            </div>
                        );
                    })
                }
            </div>
        );
    }
    setFont(f) {
        this.setState({ fm: f }, () => {
            this.resize();
        })
    }
    setFontSize(f) {
        this.setState({ fs: f }, () => {
            this.resize();
        })
    }
    goBack() {
        this.props.history.push("/book?url=" + bookfile.book.url);
    }
    render() {
        const { classes } = this.props;
        return (
            <div ref={divElement => this.divElement = divElement} onKeyDown={this.handleKeyPress.bind(this)}
                tabIndex="0" style={{ background: this.state.bg }}>
                <AppBar position="fixed" style={{ 'transition': 'top 0.5s ease', top: this.state.showbar ? 0 : -64, backgroundColor: '#333' }}>
                    <Toolbar>
                        <IconButton color="inherit" aria-label="Menu" className={classes.menuButton} onClick={() => this.goBack()}>
                            <ArrowBackIcon />
                        </IconButton>
                        <Typography type="title" color="inherit" className={classes.flex} style={{ textAlign: 'center', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            {this.state.Chap != null ? this.state.Chap.name : ""}
                        </Typography>
                        <div>
                            <IconButton color="inherit" onClick={this.handleTouchTap}>
                                <TextFormatIcon />
                            </IconButton>
                            <Popover
                                open={this.state.open}
                                anchorEl={this.state.anchorEl}
                                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                                onClose={this.handleRequestClose}
                            >
                                <div style={{ width: 360, padding: 15, maxWidth: '100%' }}>
                                    <div className={classes.row}>
                                        <div className={classes.name}>Theme</div>
                                        <div className={classes.action}>
                                            <Fab style={{ backgroundColor: '#fff', margin: 10 }} onClick={() => this.setState({ bg: '#fff' })}><div></div></Fab>
                                            <Fab style={{ backgroundColor: '#f5eacccc', margin: 10 }} onClick={() => this.setState({ bg: '#f5eacccc' })}><div></div></Fab>
                                            <Fab style={{ backgroundColor: '#e6f2e6cc', margin: 10 }} onClick={() => this.setState({ bg: '#e6f2e6cc' })}><div></div></Fab>
                                            <Fab style={{ backgroundColor: '#e4f1f5cc', margin: 10 }} onClick={() => this.setState({ bg: '#e4f1f5cc' })}><div></div></Fab>
                                            <Fab style={{ backgroundColor: '#f5e4e4cc', margin: 10 }} onClick={() => this.setState({ bg: '#f5e4e4cc' })}><div></div></Fab>
                                            <Fab style={{ backgroundColor: '#e0e0e0cc', margin: 10 }} onClick={() => this.setState({ bg: '#e0e0e0cc' })}><div></div></Fab>
                                            <Fab style={{ backgroundColor: '#191b1ccc', margin: 10 }} onClick={() => this.setState({ bg: '#191b1ccc' })}><div></div></Fab>
                                        </div>
                                    </div>
                                    <div className={classes.row}>
                                        <div className={classes.name}>Font chữ</div>
                                        <div className={classes.action}>
                                            <Button onClick={() => this.setFont('Palatino Linotype')} style={{ fontFamily: 'Palatino Linotype', margin: 10, border: '1px solid #ddd' }} color="default">Palatino</Button>
                                            <Button onClick={() => this.setFont('Times New Roman')} style={{ fontFamily: 'Times New Roman', margin: 10, border: '1px solid #ddd' }} color="default">Times</Button>
                                            <Button onClick={() => this.setFont('Arial')} style={{ fontFamily: 'Arial', margin: 10, border: '1px solid #ddd' }} color="default">Arial</Button>
                                            <Button onClick={() => this.setFont('Georgia')} style={{ fontFamily: 'Georgia', margin: 10, border: '1px solid #ddd' }} color="default">Georgia</Button>
                                            <Button onClick={() => this.setFont('sans-serif')} style={{ fontFamily: 'sans-serif', margin: 10, border: '1px solid #ddd' }} color="default">sans-serif</Button>

                                        </div>
                                    </div>
                                    <div className={classes.row}>
                                        <div className={classes.name} style={{marginBottom:5}}>Cỡ chữ</div>
                                        <div className={classes.action}>
                                            <Button onClick={() => this.setFontSize(this.state.fs - 1)} style={{ borderRadius:0,border: '1px solid #ddd' }} color="default">A-</Button>
                                            <Button onClick={() => this.setFontSize(26)} style={{ borderRadius:0,border: '1px solid #ddd', borderLeft: 0, borderRight: 0, color: 'blue' }} color="default">{this.state.fs != null ? this.state.fs : 26}</Button>
                                            <Button onClick={() => this.setFontSize(this.state.fs + 1)} style={{ borderRadius:0,border: '1px solid #ddd' }} color="default">A+</Button>
                                        </div>
                                    </div>
                                </div>
                            </Popover>
                        </div>
                        <IconButton color="inherit">
                            <Link to={"/"} style={{ color: '#fff', textDecoration: 'none' }}><HomeIcon /></Link>
                        </IconButton>
                    </Toolbar>
                </AppBar>
                {this.state.page === 0 && !this.state.showbar &&
                    <h2 className="h2Chap" style={{ position: "fixed", left: '5%', top: 0, textAlign: "center", zIndex: 1, width: "90%", fontSize: 24, overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>{this.state.Chap.name}</h2>
                }
                <div onClick={this.Prev.bind(this)} style={{ position: "fixed", zIndex: 1, left: 0, top: 0, width: this.state.column == 1 ? 80 : 200, height: window.innerHeight }}></div>
                <div style={{ padding: this.state.padding, minHeight: window.innerHeight - this.state.padding * 2, fontSize: this.state.fs + "px", fontFamily: this.state.fm, position: 'relative', overflow: 'auto', textAlign: "justify", backgroundColor: this.state.bg }}>
                    {this.renderBookData()}
                </div>
                <div onClick={this.Next.bind(this)} style={{ position: "fixed", zIndex: 1, right: 0, top: 0, width: this.state.column == 1 ? 80 : 200, height: window.innerHeight }}></div>
                <Dialog
                    title=""
                    open={this.state.loading}
                >
                    <div style={{ padding: 20, textAlign: 'center' }}><CircularProgress /><br /><span>Đang tải dữ liệu....</span></div>
                </Dialog>
                <div style={{ 'transition': 'bottom 0.5s ease', bottom: this.state.showbar ? 40 : -20, position: 'fixed', left: this.state.padding, right: this.state.padding }}>
                    <Slider defaultValue={this.state.page} value={this.state.page} min={0} max={this.state.len - 1} onChange={this.changePage.bind(this)} />
                </div>
                {this.state.column === 1 &&
                    <div style={{ display: "flex", position: 'fixed', bottom: 10, left: this.state.padding, right: this.state.padding }}>
                        <div style={{ flexBasis: "20%", textAlign: "left" }}>{parseInt(this.state.page * 100 / (this.state.len - 1)) + "%"}</div>
                        <div style={{ flexBasis: "80%", textAlign: "right", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>{this.state.Chap.name}</div>
                    </div>
                }
                {this.state.column != 1 &&
                    <div style={{ display: "flex", position: 'fixed', bottom: 10, left: this.state.padding, right: this.state.padding }}>
                        <div style={{ flexBasis: "33%", textAlign: "center" }}></div>
                        <div style={{ flexBasis: "33%", textAlign: "center" }}>{(this.state.len > 1 ? parseInt(this.state.page * 100 / (this.state.len - 1)) : 0) + "%"}</div>
                        <div style={{ flexBasis: "33%", textAlign: "right", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>{this.state.Chap.name}</div>
                    </div>
                }
            </div>
        );
    }
};
export default withStyles(styles, { withTheme: true })(ChapPage);
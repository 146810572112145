import React, { Component } from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import { withStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import MoreVertIcon from "@material-ui/icons/HelpOutline";
import SearchIcon from "@material-ui/icons/Search";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SwipeableViews from "react-swipeable-views";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import TextField from "@material-ui/core/TextField";
import Drawer from "@material-ui/core/Drawer";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Link } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Divider from "@material-ui/core/Divider";
import Input from "@material-ui/core/Input";
import Switch from "@material-ui/core/Switch";
import Badge from "@material-ui/core/Badge";
import translate from "../utils/translate";
import bookfile from "../utils/bookfile";
import scraper from "../utils/scraper";
import Avatar from "@material-ui/core/Avatar";
import ChromeReaderMode from "@material-ui/icons/ChromeReaderMode";
import WebIcon from "@material-ui/icons/Web";
import GTranslateIcon from "@material-ui/icons/Translate";
import SettingsIcon from "@material-ui/icons/Settings";
import FolderIcon from "@material-ui/icons/Folder";
import AccountCircle from "@material-ui/icons/AccountCircleOutlined";
import LinkIcon from "@material-ui/icons/Link";
import SaveIcon from "@material-ui/icons/Save";
import RemoveIcon from "@material-ui/icons/Delete";
import { Row, Col } from "react-grid-system";
import { toast } from "react-toastify";
import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from "@material-ui/icons/Close";
import { Menu, Item, Separator, IconFont, MenuProvider } from "react-contexify";
import FileReaderInput from "react-file-reader-input";
import Table, {
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core/Table";
import Paper from "@material-ui/core/Paper";
import TextFormatIcon from "@material-ui/icons/TextFormat";
import Popover from "@material-ui/core/Popover";
import FacebookLogin from "react-facebook-login";
import GoogleLogin from "react-google-login";
var fileDownload = require("react-file-download");
//var HTMLParser = require("fast-html-parser");
var mw = new Worker("worker.js");
const styles = theme => ({
  root: {
    marginTop: theme.spacing.unit * 3,
    width: "100%"
  },
  tabsIndicator: {
    backgroundColor: "#ffffff"
  },
  paper: {
    maxWidth: "600px",
    padding: 20,
    margin: "20px auto"
  },
  list: {
    width: 250
  },
  badge: {
    margin: `0 ${theme.spacing.unit * 2}px`,
    right: -24
  },
  bbadge: { position: "absolute", left: 20, top: 20 },
  bottom: { position: "fixed", width: "100%", bottom: 0 },
  flex: {
    flex: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  cardtitle: {
    fontWeight: "bold",
    fontFamily: "cursive",
    wordBreak: "initial",
    whiteSpace: "nowrap"
  },
  avatar: {
    margin: 10
  },
  bigAvatar: {
    width: 96,
    height: 126,
    minWidth: 96,
    marginRight: 10
  },
  textFieldRoot: {
    padding: 0,
    "label + &": {
      marginTop: theme.spacing.unit * 3
    }
  },
  textFieldInput: {
    borderRadius: 4,
    background: theme.palette.common.white,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 12px",
    width: "calc(100% - 24px)",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
    }
  },
  textFieldFormLabel: {
    fontSize: 18
  },
  wrapper: {
    margin: theme.spacing.unit,
    position: "relative"
  }
});
function TabContainer({ children, dir }) {
  return (
    <div dir={dir} style={{ padding: "20px 15px" }} className="tabcontent">
      {children}
    </div>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node,
  dir: PropTypes.string
};

function MainContainer({ children, dir }) {
  return (
    <div dir={dir} className="tabcontent">
      {children}
    </div>
  );
}

MainContainer.propTypes = {
  children: PropTypes.node,
  dir: PropTypes.string
};
class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openu: false,
      openpop: false,
      open: false,
      nopen: false,
      dopen: false,
      left: false,
      value: bookfile.value || 0,
      value1: bookfile.value1 || 0,
      value2: bookfile.value2 || 0,
      value3: bookfile.value3 || 0,
      loading: false,
      apiweb: !bookfile.op.api,
      Trung: "",
      HV: "",
      VP: "",
      types: [],
      pathName: "",
      pathHV: "",
      pathVP: "",
      webs: [[], [], [], []],
      bg: "#fff",
      fs: bookfile.ismobile ? 18 : 26,
      fm: "Arial"
    };
    bookfile.loadbooksMark();
    bookfile.loadWebs(
      () => {
        this.setState({ webs: bookfile.webs });
      },
      () => {}
    );
    if (!bookfile.op.api) {
      bookfile.loadVietPhrase().then(ok => {
        if (ok) {
          toast.success("Đã tải xong dữ liệu!");
        } else {
          toast.warning(
            "Chưa có dữ liệu lưu trên máy, vui lòng tải từ điển bên dưới!"
          );
        }
      });
    }
  }
  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open
    });
  };
  handleChange = (event, value) => {
    this.setState({ value });
    bookfile.value = value;
  };
  handleChangeIndex = index => {
    this.setState({ value: index });
    bookfile.value = index;
  };
  handleChange1 = (event, value1) => {
    this.setState({ value1 });
    bookfile.value1 = value1;
  };
  handleChangeIndex1 = index => {
    this.setState({ value1: index });
    bookfile.value1 = index;
  };
  handleChange2 = (event, value2) => {
    this.setState({ value2 });
    bookfile.value2 = value2;
  };
  handleChangeIndex2 = index => {
    this.setState({ value2: index });
    bookfile.value2 = index;
  };
  handleChange3 = (event, value3) => {
    this.setState({ value3 });
    bookfile.value3 = value3;
  };
  handleChangeIndex3 = index => {
    this.setState({ value3: index });
    bookfile.value3 = index;
  };
  handleMenu = event => {
    this.setState({ open: event.currentTarget });
  };

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  listBooks(b) {
    scraper.ListBooks(
      b,
      () => {
        this.setState(
          {
            loading: false
          },
          function() {}
        );
      },
      () => {
        this.setState({ loading: false });
      }
    );
  }
  handleChangeset = name => (event, checked) => {
    this.setState({ [name]: checked });
    if (name === "apiweb") {
      bookfile.op.api = !checked;
      localStorage.setItem("apiweb", bookfile.op.api);
      if (!bookfile.op.api) {
        bookfile.loadVietPhrase().then(ok => {
          if (ok) {
            toast.success("Đã tải xong dữ liệu!");
          } else {
            toast.warning(
              "Chưa có dữ liệu lưu trên máy, vui lòng tải từ điển bên dưới!"
            );
          }
        });
      }
    }
  };
  loadData(t) {
    let path = "";
    switch (t) {
      case 1:
        path = this.state.pathName;
        break;
      case 2:
        path = this.state.pathHV;
        break;
      case 3:
        path = this.state.pathVP;
        break;
    }
    this.setState({ loading: true });
    mw.postMessage(JSON.stringify({ path: path, t: t, web: true }));
    mw.onmessage = m => {
      let d = m.data;
      if (t !== 0) {
        this.setState({ loading: false });
      }
      switch (d.t) {
        case 1:
          bookfile.arrName = d.a;
          toast.success("Đã tải xong dữ liệu Name !");
          break;
        case 2:
          bookfile.arrHanViet = d.a;
          toast.success("Đã tải xong dữ liệu ChinesePhienAmWords!");
          break;
        case 3:
          bookfile.arrVietPhrase = d.a;
          toast.success("Đã tải xong dữ liệu VietPhrase!");
          bookfile.op.isload = true;
          this.setState({ loading: false });
          bookfile.saveVietPhrase();
          break;
      }
    };
  }
  goBook(b) {
    bookfile.setBook(b);
  }
  handleRequestnClose = () => {
    this.setState({ nopen: false });
  };
  confirmBook(b) {
    this.setState({ nopen: true, confirm: b });
  }
  removeBook() {
    this.setState({ nopen: false });
    bookfile.delbookMark(this.state.confirm);
    this.forceUpdate();
  }
  renderListBookData(t) {
    let books = [];
    switch (t) {
      case 1:
        books = bookfile.booksStar;
        break;
      case 2:
        books = bookfile.booksMark;
        break;
      case 3:
        books = bookfile.booksDownload;
        break;
    }
    if (books.length > 0) {
      const { classes } = this.props;
      return (
        <Row>
          {books.map((item, i) => (
            <Col
              xs={12}
              sm={6}
              md={6}
              lg={4}
              xl={3}
              key={i}
              style={{ marginBottom: 20 }}
            >
              <Card>
                <CardContent
                  style={{
                    textAlign: "center",
                    justifyContent: "center",
                    height: 120,
                    position: "relative"
                  }}
                  onClick={() => this.goBook(item)}
                >
                  <Link
                    to={"/book"}
                    style={{
                      color: "#000",
                      textDecoration: "none",
                      display: "flex",
                      overflow: "hidden"
                    }}
                  >
                    <img
                      alt=""
                      src={
                        item.image ||
                        "http://vietphrase.info/images/fengmian.jpg"
                      }
                      className={classes.bigAvatar}
                    />
                    <div
                      style={{
                        textAlign: "left",
                        maxWidth: "calc(100% - 100px)"
                      }}
                    >
                      <div
                        style={{
                          fontWeight: "bold",
                          fontSize: 16,
                          marginBottom: 5
                        }}
                      >
                        {item.title}
                      </div>
                      <div>{item.author}</div>
                      <div>{(item.newchapdes + "").toString().trim()}</div>
                      {item.chap && (
                        <div className="nowrap" style={{ color: "#f50057" }}>
                          Đang đọc: {item.chap.name}
                        </div>
                      )}
                      <div
                        className="nowrap"
                        style={{
                          color: "#666",
                          fontStyle: "italic",
                          fontSize: "13"
                        }}
                      >
                        Ngày đọc: {item.Date}
                      </div>
                      <Badge
                        className={classes.bbadge}
                        badgeContent={i + 1}
                        color="primary"
                      >
                        {""}
                      </Badge>
                    </div>
                  </Link>
                  <IconButton
                    style={{
                      position: "absolute",
                      backgroundColor: "#fff",
                      right: 5,
                      bottom: -5
                    }}
                    onClick={() => this.confirmBook(item)}
                  >
                    <RemoveIcon />
                  </IconButton>
                </CardContent>
              </Card>
            </Col>
          ))}
        </Row>
      );
    }
  }
  renderBookData() {
    return (
      <div>
        <SwipeableViews
          axis={"x-reverse"}
          index={this.state.value1}
          onChangeIndex={this.handleChangeIndex1}
        >
          <TabContainer />
          <TabContainer>{this.renderListBookData(2)}</TabContainer>
          <TabContainer />
        </SwipeableViews>
      </div>
    );
  }
  getText(el) {
    return el.innerHTML || this.getTextForFirefox(el);
  }
  getTextForFirefox(el) {
    // Taken from http://stackoverflow.com/a/3908094
    var text = "";
    if (typeof window.getSelection != "undefined") {
      var sel = window.getSelection();
      var tempRange = sel.getRangeAt(0);
      sel.removeAllRanges();
      var range = document.createRange();
      range.selectNodeContents(el);
      sel.addRange(range);
      text = sel.toString();
      sel.removeAllRanges();
      sel.addRange(tempRange);
    }

    return text;
  }
  onTextChange(ev) {
    var text = this.getText(ev.target);
    this.setState({ Trung: text });
  }
  onpasText(ev) {
    document.getElementById("divTrung").innerHTML = "";
    //let doc = HTMLParser.parse(ev.clipboardData.getData("text/html"));
    //this.setState({ Trung: document.structuredText });
  }
  RemoveTXT() {
    this.setState({ Trung: "", HV: "", VP: "", value3: 0 });
  }
  Translate() {
    this.setState({ loading: true });
    if (bookfile.op.api) {
      this.transHtml(this.state.Trung);
    } else {
      this.setState({
        loading: false,
        value3: 5,
        HV: translate.ChineseToHanVietForBrowser(this.state.Trung),
        VP: translate.ChineseToVietPhraseOneMeaningForBrowser(this.state.Trung)
      });
    }
  }
  transHtml(html) {
    scraper.transLate(
      html,
      data => {
        this.setState({ loading: false, value3: 5, VP: data.vp, HV: data.hv });
      },
      error => {}
    );
  }
  transHV(html) {
    scraper.transLateHV(
      html,
      data => {
        console.log(data);
        this.setState({ HV: data });
      },
      error => {}
    );
  }
  FileChange = (e, results) => {
    let that = this;
    this.setState({ brower: true });
    setTimeout(() => {
      let files = [];
      results.forEach(result => {
        const [, file] = result;
        files.push({
          name: file.name,
          size: file.size,
          content: result[0].target.result,
          tran: false,
          contenthv: "",
          contentvp: "",
          loading: false
        });
      });
      that.setState({ files: files, brower: false });
    }, 1);
  };
  FileLocalName = (e, results) => {
    let that = this;
    this.setState({ browerName: true });
    setTimeout(() => {
      results.forEach(result => {
        const [, file] = result;
        that.setState({
          FNames: result[0].target.result,
          pathName: file.name,
          browerName: false
        });
      });
    }, 1);
  };
  FileLocalHV = (e, results) => {
    let that = this;
    this.setState({ browerHV: true });
    setTimeout(() => {
      results.forEach(result => {
        const [, file] = result;
        that.setState({
          FHV: result[0].target.result,
          pathHV: file.name,
          browerHV: false
        });
      });
    }, 1);
  };
  FileLocalVP = (e, results) => {
    let that = this;
    this.setState({ browerVP: true });
    setTimeout(() => {
      results.forEach(result => {
        const [, file] = result;
        that.setState({
          FVP: result[0].target.result,
          pathVP: file.name,
          browerVP: false
        });
      });
    }, 1);
  };
  RemoveFile(i) {
    let files = this.state.files;
    files.splice(i, 1);
    this.setState({ files: files });
  }
  TranslateFile(i) {
    let files = this.state.files;
    if (i === -1) {
      for (let f of files) {
        f.loading = true;
      }
    } else {
      let f = files[i];
      f.loading = true;
    }
    this.setState({ files: files });
    //
    let that = this;
    setTimeout(() => {
      if (i === -1) {
        for (let f of files) {
          f.tran = true;
          f.loading = false;
          //f.contenthv = translate.ChineseToHanVietForBrowser(f.content);
          //f.contentvp = translate.ChineseToVietPhraseOneMeaningForBrowser(f.content);
          f.content = translate.ChineseToVietPhraseOneMeaningForBrowser(
            f.content
          );
        }
      } else {
        let f = files[i];
        f.tran = true;
        f.loading = false;
        //f.contenthv = translate.ChineseToHanVietForBrowser(f.content);
        //f.contentvp = translate.ChineseToVietPhraseOneMeaningForBrowser(f.content);
        f.content = translate.ChineseToVietPhraseOneMeaningForBrowser(
          f.content
        );
      }
      that.setState({ files: files });
    }, 1);
  }
  getNameFileVP(name) {
    return (
      name.substring(0, name.lastIndexOf(".")) +
      "_VP." +
      name.substr(name.lastIndexOf(".") + 1)
    );
  }
  DownloadFile(i) {
    let files = this.state.files;
    if (i === -1) {
      for (let f of files) {
        fileDownload(f.content, this.getNameFileVP(f.name));
      }
    } else {
      let f = files[i];
      fileDownload(f.content, this.getNameFileVP(f.name));
    }
  }
  saveSetting() {
    let t = 0;
    this.setState({ loading: true });
    mw.postMessage(
      JSON.stringify({
        arr: {
          arrName: this.state.FNames,
          arrHanViet: this.state.FHV,
          arrVietPhrase: this.state.FVP
        },
        t: t,
        web: false
      })
    );
    mw.onmessage = m => {
      let d = m.data;
      if (t !== 0) {
        this.setState({ loading: false });
      }
      switch (d.t) {
        case 1:
          bookfile.arrName = d.a;
          toast.success("Đã tải xong dữ liệu Name !");
          break;
        case 2:
          bookfile.arrHanViet = d.a;
          toast.success("Đã tải xong dữ liệu ChinesePhienAmWords!");
          break;
        case 3:
          bookfile.arrVietPhrase = d.a;
          toast.success("Đã tải xong dữ liệu VietPhrase!");
          bookfile.op.isload = true;
          this.setState({ loading: false });
          bookfile.saveVietPhrase();
          break;
      }
    };
  }
  dichWeb(url) {
    if (url != null && url != "") {
      this.state.webUrl = url;
    }
    bookfile.setWebUrl(this.state.webUrl);
  }
  _handleKeyPress = e => {
    if (e.key === "Enter") {
      this.dichWeb();
      this.props.history.push("/web");
    }
  };
  renderVietPhrase() {
    const { classes } = this.props;
    return (
      <div>
        <SwipeableViews
          axis={"x-reverse"}
          index={this.state.value3}
          onChangeIndex={this.handleChangeIndex3}
        >
          <TabContainer>
            <div className="tabCTdiv" style={{ display: "table", padding: 20 }}>
              <div
                style={{
                  display: "table-cell",
                  verticalAlign: "middle",
                  textAlign: "center"
                }}
              >
                <div style={{ maxWidth: 960, margin: "auto" }}>
                  <img
                    src="/assest/vp.png"
                    style={{
                      marginBottom: 20,
                      maxWidth: "100%",
                      maxHeight: "100px"
                    }}
                  />
                  <TextField
                    onChange={event =>
                      this.setState({ webUrl: event.target.value })
                    }
                    onKeyPress={this._handleKeyPress}
                    placeholder="Nhập trang cần dịch"
                    fullWidth
                    variant="outlined"
                  />
                  <Link
                    onClick={() => this.dichWeb()}
                    to={"/web"}
                    style={{ color: "#fff", textDecoration: "none" }}
                  >
                    <Button
                      style={{ marginTop: 20 }}
                      variant="contained"
                      color="primary"
                    >
                      Dịch trang này
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </TabContainer>
          <TabContainer>
            <Row>
              {this.state.webs[0].map(tile => (
                <Col
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  xl={2}
                  key={tile.isKey}
                  style={{ marginBottom: 10, overflow: "inherit" }}
                >
                  <Card>
                    <CardContent
                      style={{
                        textAlign: "center",
                        justifyContent: "center",
                        height: 80
                      }}
                    >
                      <Link
                        onClick={() => this.dichWeb(tile.web)}
                        to={"/web"}
                        style={{ color: "#000", textDecoration: "none" }}
                      >
                        <img
                          src={tile.icon}
                          alt={tile.isKey}
                          onError={() =>
                            (tile.icon = "http://vietphrase.info/logo.png")
                          }
                          height="45"
                          style={{ maxWidth: "100%", objectFit: "scale-down" }}
                        />
                        <div
                          style={{
                            fontWeight: "bold",
                            fontSize: 17,
                            fontFamily: "cursive",
                            wordBreak: "initial",
                            whiteSpace: "nowrap"
                          }}
                        >
                          {tile.isKey}
                        </div>
                      </Link>
                    </CardContent>
                  </Card>
                </Col>
              ))}
            </Row>
          </TabContainer>
          <TabContainer>
            <div
              className="tabCTdiv"
              style={{ backgroundColor: "transparent", display: "table" }}
            >
              <div
                style={{
                  display: "table-cell",
                  verticalAlign: "middle",
                  textAlign: "center"
                }}
              >
                <Paper className={classes.paper}>
                  <form>
                    <label htmlFor="my-file-input">
                      <b>Tải file TXT cần dịch:</b>
                    </label>
                    <FileReaderInput
                      multiple
                      as="text"
                      id="my-file-input"
                      onChange={this.FileChange.bind(this)}
                    >
                      <div className={classes.wrapper}>
                        {this.state.brower && (
                          <div>
                            <CircularProgress
                              size={24}
                              className={classes.buttonProgress}
                            />
                          </div>
                        )}
                        <Button
                          style={{ marginTop: 20 }}
                          variant="contained"
                          color="primary"
                          disabled={this.state.brower}
                        >
                          Chọn file
                        </Button>
                      </div>
                    </FileReaderInput>
                  </form>
                </Paper>
                {this.state.files && this.state.files.length > 0 && (
                  <Paper className={classes.paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            style={{
                              fontWeight: "bold",
                              fontSize: "1rem",
                              padding: 4
                            }}
                          >
                            TT
                          </TableCell>
                          <TableCell
                            style={{
                              fontWeight: "bold",
                              fontSize: "1rem",
                              padding: 4
                            }}
                          >
                            Tên file
                          </TableCell>
                          <TableCell style={{ padding: 4 }}>
                            <Button
                              onClick={() => this.TranslateFile(-1)}
                              raised
                              color="secondary"
                            >
                              Dịch tất cả
                            </Button>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.files.map((n, i) => {
                          return (
                            <TableRow key={i}>
                              <TableCell style={{ padding: 4 }}>
                                {i + 1}
                              </TableCell>
                              <TableCell style={{ padding: 4 }}>
                                {n.name}
                              </TableCell>
                              <TableCell style={{ padding: 4 }}>
                                {n.loading && <CircularProgress size={24} />}
                                {!n.loading && !n.tran && (
                                  <IconButton
                                    onClick={() => this.TranslateFile(i)}
                                    className={classes.button}
                                    aria-label="Dịch"
                                  >
                                    <GTranslateIcon />
                                  </IconButton>
                                )}
                                {!n.loading && n.tran && (
                                  <IconButton
                                    onClick={() => this.DownloadFile(i)}
                                    color="primary"
                                    className={classes.button}
                                    aria-label="Tải về"
                                  >
                                    <SaveIcon />
                                  </IconButton>
                                )}
                                <IconButton
                                  onClick={() => this.RemoveFile(i)}
                                  className={classes.button}
                                  aria-label="Xóa"
                                >
                                  <RemoveIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </Paper>
                )}
              </div>
            </div>
          </TabContainer>
          <TabContainer>
            <div style={{ position: "relative" }}>
              <div
                id="divTrung"
                ref="content"
                style={{ padding: 20 }}
                spellCheck="false"
                contentEditable="true"
                onBlur={this.onTextChange.bind(this)}
                onPaste={this.onpasText.bind(this)}
                className="tabCTdiv ttdiv"
                //dangerouslySetInnerHTML={{ __html: this.state.Trung }}
              />
              <Fab
                color="secondary"
                aria-label="Dịch"
                style={{ position: "absolute", bottom: 20, right: 20 }}
                onClick={this.Translate.bind(this)}
              >
                <GTranslateIcon />
              </Fab>
            </div>
          </TabContainer>
          <TabContainer>
            <div
              ref="contentHV"
              style={{
                padding: 20,
                fontSize: this.state.fs + "px",
                fontFamily: this.state.fm,
                position: "relative",
                overflow: "auto",
                textAlign: "justify",
                backgroundColor: this.state.bg
              }}
              spellCheck="false"
              contentEditable="true"
              className="tabCTdiv ttdiv"
              dangerouslySetInnerHTML={{ __html: this.state.HV }}
            />
          </TabContainer>
          {bookfile.op.api ? (
            <TabContainer>
              <div
                ref="contentVP"
                style={{
                  padding: 20,
                  fontSize: this.state.fs + "px",
                  fontFamily: this.state.fm,
                  position: "relative",
                  overflow: "auto",
                  textAlign: "justify",
                  backgroundColor: this.state.bg
                }}
                spellCheck="false"
                contentEditable="true"
                className="tabCTdiv ttdiv"
                dangerouslySetInnerHTML={{ __html: this.state.VP }}
              />
            </TabContainer>
          ) : (
            <TabContainer>
              <MenuProvider id="divVP">
                <div
                  ref="contentVP"
                  style={{
                    padding: 20,
                    fontSize: this.state.fs + "px",
                    fontFamily: this.state.fm,
                    position: "relative",
                    overflow: "auto",
                    textAlign: "justify",
                    backgroundColor: this.state.bg
                  }}
                  spellCheck="false"
                  contentEditable="true"
                  className="tabCTdiv ttdiv"
                  dangerouslySetInnerHTML={{ __html: this.state.VP }}
                />
              </MenuProvider>
              <Menu id="divVP">
                <Item leftIcon={<IconFont className="fa fa-save" />}>
                  {" "}
                  Cập nhật Name
                </Item>
                <Item leftIcon={<IconFont className="fa fa-save" />}>
                  Cập nhật VietPhrase
                </Item>
                <Separator />
                <Item
                  leftIcon={<IconFont className="fa fa-trash" />}
                  onClick={this.RemoveTXT.bind(this)}
                >
                  Xóa Text nguồn
                </Item>
              </Menu>
            </TabContainer>
          )}
        </SwipeableViews>
      </div>
    );
  }
  setFont(f) {
    this.setState({ fm: f });
  }
  setFontSize(f) {
    this.setState({ fs: f });
  }
  renderWebSource() {
    return (
      <div className="tpanel" style={{ height: "calc(100vh - 160px)" }}>
        <SwipeableViews
          axis={"x"}
          index={this.state.value2}
          onChangeIndex={this.handleChangeIndex2}
        >
          {this.state.webs.map((web, i) => (
            <TabContainer key={i}>
              <Row>
                {web.map(w => (
                  <Col
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    xl={2}
                    key={w.isKey}
                    style={{ marginBottom: 10, overflow: "inherit" }}
                  >
                    <Card>
                      <CardContent
                        style={{
                          textAlign: "center",
                          justifyContent: "center",
                          height: 80
                        }}
                      >
                        <Link
                          to={"/list?url=" + w.web}
                          style={{ color: "#000", textDecoration: "none" }}
                        >
                          <img
                            src={w.icon}
                            alt={w.isKey}
                            height="45"
                            style={{
                              maxWidth: "100%",
                              objectFit: "scale-down"
                            }}
                          />
                          <div
                            style={{
                              fontWeight: "bold",
                              fontSize: 17,
                              fontFamily: "cursive",
                              wordBreak: "initial",
                              whiteSpace: "nowrap",
                              overflow: "hidden"
                            }}
                          >
                            {w.isKey}
                          </div>
                        </Link>
                      </CardContent>
                    </Card>
                  </Col>
                ))}
              </Row>
            </TabContainer>
          ))}
        </SwipeableViews>
      </div>
    );
  }
  renderSetting() {
    const { classes } = this.props;
    return (
      <div style={{ overflow: "hidden", padding: 10 }}>
        <List>
          <ListItem button style={{ backgroundColor: "#fff" }}>
            <ListItemAvatar>
              <Avatar>
                <FolderIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Sử dụng từ điển trên máy" secondary={null} />
            <ListItemSecondaryAction>
              <Switch
                checked={this.state.apiweb}
                onChange={this.handleChangeset("apiweb")}
                aria-label="apiweb"
              />
            </ListItemSecondaryAction>
          </ListItem>
        </List>
        {this.state.apiweb && (
          <List>
            <ListItem button style={{ backgroundColor: "#fff" }}>
              <ListItemAvatar>
                <Avatar>
                  <LinkIcon />
                </Avatar>
              </ListItemAvatar>
              <Input
                fullWidth={true}
                disableUnderline={true}
                style={{ marginLeft: 10, marginRight: 0 }}
                onChange={txt => this.setState({ pathName: txt })}
                defaultValue={this.state.pathName}
                placeholder="Đường dẫn Name"
              />
              <ListItemSecondaryAction>
                <FileReaderInput
                  as="text"
                  id="my-file-input"
                  onChange={this.FileLocalName.bind(this)}
                >
                  {this.state.browerName && (
                    <div>
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    </div>
                  )}
                  <Button
                    style={{ marginTop: 5 }}
                    raised
                    disabled={this.state.browerName}
                  >
                    Chọn file Names
                  </Button>
                </FileReaderInput>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem button style={{ backgroundColor: "#fff" }}>
              <ListItemAvatar>
                <Avatar>
                  <LinkIcon />
                </Avatar>
              </ListItemAvatar>
              <Input
                fullWidth={true}
                disableUnderline={true}
                style={{ marginLeft: 10, marginRight: 0 }}
                onChange={txt => this.setState({ pathHV: txt })}
                defaultValue={this.state.pathHV}
                placeholder="Đường dẫn ChinesePhienAmWords"
              />
              <ListItemSecondaryAction>
                <FileReaderInput
                  as="text"
                  id="my-file-input"
                  onChange={this.FileLocalHV.bind(this)}
                >
                  {this.state.browerHV && (
                    <div>
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    </div>
                  )}
                  <Button
                    style={{ marginTop: 5 }}
                    raised
                    disabled={this.state.browerHV}
                  >
                    Chọn file ChinesePhienAmWords
                  </Button>
                </FileReaderInput>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem button style={{ backgroundColor: "#fff" }}>
              <ListItemAvatar>
                <Avatar>
                  <LinkIcon />
                </Avatar>
              </ListItemAvatar>
              <Input
                fullWidth={true}
                disableUnderline={true}
                style={{ marginLeft: 10, marginRight: 0 }}
                onChange={txt => this.setState({ pathVP: txt })}
                defaultValue={this.state.pathVP}
                placeholder="Đường dẫn VietPhrase"
              />
              <ListItemSecondaryAction>
                <FileReaderInput
                  as="text"
                  id="my-file-input"
                  onChange={this.FileLocalVP.bind(this)}
                >
                  {this.state.browerVP && (
                    <div>
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    </div>
                  )}
                  <Button
                    style={{ marginTop: 5 }}
                    raised
                    disabled={this.state.browerVP}
                  >
                    Chọn file VietPhrase
                  </Button>
                </FileReaderInput>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem button style={{ justifyContent: "flex-end" }}>
              <Fab
                color="primary"
                aria-label="Lưu lại"
                onClick={this.saveSetting.bind(this)}
              >
                <SaveIcon />
              </Fab>
            </ListItem>
          </List>
        )}
      </div>
    );
  }
  renderTabKeSach() {
    const { classes } = this.props;
    return (
      <Tabs
        value={this.state.value1}
        onChange={this.handleChange1}
        classes={{ indicator: classes.tabsIndicator }}
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab
          style={{ overflow: "unset" }}
          label={
            <Badge
              className={classes.badge}
              badgeContent={bookfile.booksStar.length}
              color="secondary"
            >
              <span style={{ paddingRight: 15 }}>Kệ sách</span>
            </Badge>
          }
        />
        <Tab
          style={{ overflow: "unset" }}
          label={
            <Badge
              className={classes.badge}
              badgeContent={bookfile.booksMark.length}
              color="secondary"
            >
              <span style={{ paddingRight: 15 }}>Lịch sử</span>
            </Badge>
          }
        />
        <Tab
          style={{ overflow: "unset" }}
          label={
            <Badge
              className={classes.badge}
              badgeContent={bookfile.booksDownload.length}
              color="secondary"
            >
              <span style={{ paddingRight: 15 }}>Tải về</span>
            </Badge>
          }
        />
      </Tabs>
    );
  }
  renderTabDich() {
    const { classes } = this.props;
    return (
      <Tabs
        value={this.state.value3}
        onChange={this.handleChange3}
        classes={{ indicator: classes.tabsIndicator }}
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab label="Dịch cả trang" />
        <Tab label="Chọn nguồn" />
        <Tab label="Quick Convert" />
        <Tab label="Tiếng Trung" />
        <Tab label="Hán Việt" />
        <Tab label="VietPhrase" />
      </Tabs>
    );
  }
  renderTabNguon() {
    const { classes } = this.props;
    return (
      <Tabs
        value={this.state.value2}
        onChange={this.handleChange2}
        classes={{ indicator: classes.tabsIndicator }}
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab
          label={
            <Badge
              className={classes.badge}
              badgeContent={this.state.webs[0].length}
              color="secondary"
            >
              <span style={{ paddingRight: 15 }}>Tiếng Trung</span>
            </Badge>
          }
        />
        <Tab
          label={
            <Badge
              className={classes.badge}
              badgeContent={this.state.webs[1].length}
              color="secondary"
            >
              <span style={{ paddingRight: 15 }}>Tiếng Việt</span>
            </Badge>
          }
        />
        <Tab
          label={
            <Badge
              className={classes.badge}
              badgeContent={this.state.webs[2].length}
              color="secondary"
            >
              <span style={{ paddingRight: 15 }}>Truyện Tranh</span>
            </Badge>
          }
        />
        <Tab
          label={
            <Badge
              className={classes.badge}
              badgeContent={this.state.webs[3].length}
              color="secondary"
            >
              <span style={{ paddingRight: 15 }}>Truyện Audio</span>
            </Badge>
          }
        />
      </Tabs>
    );
  }
  renderDrawer() {
    const { classes } = this.props;
    const sideList = (
      <div className={classes.list}>
        <div
          style={{
            backgroundColor: "#3f51b5",
            textAlign: "center",
            padding: "40px 0",
            color: "#fff",
            fontWeight: "bold",
            fontFamily: "cursive",
            fontSize: 18
          }}
        >
          VIETPHRASE
        </div>
        <List>
          <ListItem button onClick={() => this.setState({ value: 0 })}>
            <ListItemIcon>
              <ChromeReaderMode />
            </ListItemIcon>
            <ListItemText inset primary="Kệ sách" />
          </ListItem>
          <ListItem button onClick={() => this.setState({ value: 1 })}>
            <ListItemIcon>
              <WebIcon />
            </ListItemIcon>
            <ListItemText inset primary="Nguồn" />
          </ListItem>
          <ListItem button onClick={() => this.setState({ value: 2 })}>
            <ListItemIcon>
              <GTranslateIcon />
            </ListItemIcon>
            <ListItemText inset primary="Dịch" />
          </ListItem>
          <ListItem
            button
            onClick={() => this.setState({ value: 2, value: 1 })}
          >
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText inset primary="Cài đặt" />
          </ListItem>
        </List>
        <Divider />
        <ListItem button onClick={() => this.setState({ value: 0, value1: 1 })}>
          <ListItemIcon>
            <FolderIcon />
          </ListItemIcon>
          <ListItemText inset primary="Truyện đã đọc" />
        </ListItem>
        <ListItem button onClick={() => this.setState({ value: 0, value1: 2 })}>
          <ListItemIcon>
            <SaveIcon />
          </ListItemIcon>
          <ListItemText inset primary="Truyện đã tải về" />
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <MoreVertIcon />
          </ListItemIcon>
          <ListItemText inset primary="Trợ giúp" />
        </ListItem>
      </div>
    );
    return (
      <Drawer open={this.state.left} onClose={this.toggleDrawer("left", false)}>
        <div
          tabIndex={0}
          role="button"
          onClick={this.toggleDrawer("left", false)}
          onKeyDown={this.toggleDrawer("left", false)}
        >
          {sideList}
        </div>
      </Drawer>
    );
  }
  DownloadBook() {
    if (this.state.link === undefined) {
      toast.error("Vui lòng nhập link hoặc tên truyện để tải!");
      return false;
    }
    let b = {
      url: this.state.link,
      title: "",
      author: "",
      newchapdes: "",
      des: ""
    };
    b.id = b.url.replace(/\D/g, "");
    bookfile.setBook(b);
    bookfile.setWeb(b.url);
    this.props.history.push("/book");
  }
  Search() {
    if (this.state.link === undefined) {
      toast.error("Vui lòng nhập link hoặc tên truyện để tải!");
      return false;
    }
  }
  handleRequestClosepop = () => {
    this.setState({ openpop: false });
  };
  handleTouchTap = event => {
    // This prevents ghost click.
    event.preventDefault();

    this.setState({
      openpop: true,
      anchorEl: event.currentTarget
    });
  };
  handleRequestCloseu = () => {
    this.setState({ openu: false });
  };
  handleTouchTapu = event => {
    // This prevents ghost click.
    event.preventDefault();

    this.setState({
      openu: true,
      anchorElu: event.currentTarget
    });
  };
  responseFacebook = response => {
    console.log(response);
  };

  responseGoogle = response => {
    console.log(response);
  };
  render() {
    const { classes } = this.props;
    return (
      <div>
        <AppBar position="fixed" style={{ zIndex: 1 }}>
          <Toolbar>
            <IconButton
              onClick={this.toggleDrawer("left", true)}
              color="inherit"
              aria-label="Menu"
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Typography type="title" color="inherit" className={classes.flex}>
              VIETPHRASE
            </Typography>
            <div>
              {this.state.value3 >= 3 && (
                <IconButton color="inherit" onClick={this.handleTouchTap}>
                  <TextFormatIcon />
                </IconButton>
              )}
              <Tooltip title="Xem hướng dẫn">
                <IconButton
                  aria-owns={!this.state.open ? "menu-appbar" : null}
                  aria-haspopup="true"
                  onClick={this.handleMenu}
                  color="inherit"
                >
                  <MoreVertIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Thông tin tài khoản">
                <IconButton
                  aria-haspopup="true"
                  color="inherit"
                  onClick={this.handleTouchTapu}
                >
                  <AccountCircle />
                </IconButton>
              </Tooltip>
              <Popover
                open={this.state.openu}
                anchorEl={this.state.anchorElu}
                anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                onClose={this.handleRequestCloseu}
              >
                <div style={{ width: 250, padding: 15, maxWidth: "100%" }}>
                  <FacebookLogin
                    //size="small"
                    cssClass="btnFacebook"
                    icon="fa-facebook"
                    appId="1889743147803246" //APP ID NOT CREATED YET
                    fields="name,email,picture"
                    textButton=" Đăng nhập bằng FaceBook"
                    callback={this.responseFacebook}
                  />
                  <GoogleLogin
                    className="btnGoogle"
                    clientId="201003301427-jmgvtosofrnh51265p2jo9u2sa5d51ia.apps.googleusercontent.com" //CLIENTID NOT CREATED YET
                    buttonText="Đăng nhập bằng Google"
                    size="small"
                    onSuccess={this.responseGoogle}
                    onFailure={this.responseGoogle}
                  />
                  <List component="nav">
                    <ListItem
                      button
                      //onClick={}
                    >
                      <ListItemIcon>
                        <AccountCircle />
                      </ListItemIcon>
                      <ListItemText primary="Đăng nhập" />
                    </ListItem>
                    <ListItem
                      button
                      //onClick={}
                    >
                      <ListItemIcon>
                        <AccountCircle />
                      </ListItemIcon>
                      <ListItemText primary="Đăng ký" />
                    </ListItem>
                  </List>
                </div>
              </Popover>
              <Popover
                open={this.state.openpop}
                anchorEl={this.state.anchorEl}
                anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                onClose={this.handleRequestClosepop}
              >
                <div style={{ width: 360, padding: 15, maxWidth: "100%" }}>
                  <div className={classes.row}>
                    <div className={classes.name}>Theme</div>
                    <div className={classes.action}>
                      <Fab
                        style={{ backgroundColor: "#fff", margin: 10 }}
                        onClick={() => this.setState({ bg: "#fff" })}
                      >
                        <div />
                      </Fab>
                      <Fab
                        style={{ backgroundColor: "#f5eacccc", margin: 10 }}
                        onClick={() => this.setState({ bg: "#f5eacccc" })}
                      >
                        <div />
                      </Fab>
                      <Fab
                        style={{ backgroundColor: "#e6f2e6cc", margin: 10 }}
                        onClick={() => this.setState({ bg: "#e6f2e6cc" })}
                      >
                        <div />
                      </Fab>
                      <Fab
                        style={{ backgroundColor: "#e4f1f5cc", margin: 10 }}
                        onClick={() => this.setState({ bg: "#e4f1f5cc" })}
                      >
                        <div />
                      </Fab>
                      <Fab
                        style={{ backgroundColor: "#f5e4e4cc", margin: 10 }}
                        onClick={() => this.setState({ bg: "#f5e4e4cc" })}
                      >
                        <div />
                      </Fab>
                      <Fab
                        style={{ backgroundColor: "#e0e0e0cc", margin: 10 }}
                        onClick={() => this.setState({ bg: "#e0e0e0cc" })}
                      >
                        <div />
                      </Fab>
                      <Fab
                        style={{ backgroundColor: "#191b1ccc", margin: 10 }}
                        onClick={() => this.setState({ bg: "#191b1ccc" })}
                      >
                        <div />
                      </Fab>
                    </div>
                  </div>
                  <div className={classes.row}>
                    <div className={classes.name}>Font chữ</div>
                    <div className={classes.action}>
                      <Button
                        onClick={() => this.setFont("Palatino Linotype")}
                        style={{
                          fontFamily: "Palatino Linotype",
                          margin: 10,
                          border: "1px solid #ddd"
                        }}
                        color="default"
                      >
                        Palatino
                      </Button>
                      <Button
                        onClick={() => this.setFont("Times New Roman")}
                        style={{
                          fontFamily: "Times New Roman",
                          margin: 10,
                          border: "1px solid #ddd"
                        }}
                        color="default"
                      >
                        Times
                      </Button>
                      <Button
                        onClick={() => this.setFont("Arial")}
                        style={{
                          fontFamily: "Arial",
                          margin: 10,
                          border: "1px solid #ddd"
                        }}
                        color="default"
                      >
                        Arial
                      </Button>
                      <Button
                        onClick={() => this.setFont("Georgia")}
                        style={{
                          fontFamily: "Georgia",
                          margin: 10,
                          border: "1px solid #ddd"
                        }}
                        color="default"
                      >
                        Georgia
                      </Button>
                      <Button
                        onClick={() => this.setFont("sans-serif")}
                        style={{
                          fontFamily: "sans-serif",
                          margin: 10,
                          border: "1px solid #ddd"
                        }}
                        color="default"
                      >
                        sans-serif
                      </Button>
                    </div>
                  </div>
                  <div className={classes.row}>
                    <div className={classes.name} style={{ marginBottom: 5 }}>
                      Cỡ chữ
                    </div>
                    <div className={classes.action}>
                      <Button
                        onClick={() => this.setFontSize(this.state.fs - 1)}
                        style={{ borderRadius: 0, border: "1px solid #ddd" }}
                        color="default"
                      >
                        A-
                      </Button>
                      <Button
                        onClick={() => this.setFontSize(26)}
                        style={{
                          borderRadius: 0,
                          border: "1px solid #ddd",
                          borderLeft: 0,
                          borderRight: 0,
                          color: "blue"
                        }}
                        color="default"
                      >
                        {this.state.fs != null ? this.state.fs : 26}
                      </Button>
                      <Button
                        onClick={() => this.setFontSize(this.state.fs + 1)}
                        style={{ borderRadius: 0, border: "1px solid #ddd" }}
                        color="default"
                      >
                        A+
                      </Button>
                    </div>
                  </div>
                </div>
              </Popover>
            </div>
          </Toolbar>
          {this.state.value === 0 && this.renderTabKeSach()}
          {this.state.value === 1 && this.renderTabNguon()}
          {this.state.value === 2 && this.renderTabDich()}
        </AppBar>
        {this.renderDrawer()}
        <div style={{ paddingTop: this.state.value != 3 ? 100 : 50 }}>
          <SwipeableViews
            axis={"x"}
            index={this.state.value}
            onChangeIndex={this.handleChangeIndex}
          >
            <MainContainer>
              {this.renderBookData()}
              <Fab
                color="secondary"
                aria-label="Tìm kiếm"
                style={{ position: "fixed", bottom: 70, right: 20 }}
                onClick={() => this.setState({ dopen: true })}
              >
                <SearchIcon />
              </Fab>
            </MainContainer>
            <MainContainer>{this.renderWebSource()}</MainContainer>
            <MainContainer>{this.renderVietPhrase()}</MainContainer>
            <MainContainer>{this.renderSetting()}</MainContainer>
          </SwipeableViews>
          <Dialog title="" open={this.state.loading}>
            <div style={{ padding: 20, textAlign: "center" }}>
              <CircularProgress />
              <br />
              <span>Đang tải dữ liệu....</span>
            </div>
          </Dialog>
          <Dialog
            open={this.state.dopen}
            onClose={() => this.setState({ dopen: false })}
          >
            <DialogTitle>Link truyện</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Vui lòng nhập link truyện cần tải hoặc link trang web cần
                convert.
              </DialogContentText>
              <TextField
                autoFocus
                onChange={event => this.setState({ link: event.target.value })}
                margin="dense"
                id="link"
                label="Link"
                type="text"
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => this.setState({ dopen: false })}
                color="primary"
              >
                Thoát
              </Button>
              <Button onClick={this.Search.bind(this)} color="primary">
                Tìm
              </Button>
              <Button onClick={this.DownloadBook.bind(this)} color="primary">
                Tải
              </Button>
            </DialogActions>
          </Dialog>
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
            open={this.state.nopen}
            autoHideDuration={6000}
            //onClose={this.handleRequestnClose.bind(this)}
            SnackbarContentProps={{
              "aria-describedby": "message-id"
            }}
            message={
              <span id="message-id">
                Bạn muốn xóa truyện{" "}
                {this.confirm != null ? this.confirm.title : ""} này không?
              </span>
            }
            action={[
              <Button
                key="undo"
                color="secondary"
                dense
                onClick={this.removeBook.bind(this)}
              >
                Xóa
              </Button>,
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                className={classes.close}
                onClick={this.handleRequestnClose.bind(this)}
              >
                <CloseIcon />
              </IconButton>
            ]}
          />
        </div>
        <BottomNavigation
          value={this.state.value}
          onChange={this.handleChange}
          showLabels
          className={classes.bottom}
        >
          <BottomNavigationAction label="Kệ sách" icon={<ChromeReaderMode />} />
          <BottomNavigationAction label="Nguồn" icon={<WebIcon />} />
          <BottomNavigationAction label="Dịch" icon={<GTranslateIcon />} />
          <BottomNavigationAction label="Cài đặt" icon={<SettingsIcon />} />
        </BottomNavigation>
      </div>
    );
  }
}
const homeView = withStyles(styles, { withTheme: true })(HomePage);
export default homeView;

import React from "react";
import AppBar from "@material-ui/core/AppBar";
import {
  withStyles,
  MuiThemeProvider,
  createMuiTheme
} from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Link } from "react-router-dom";
import bookfile from "../utils/bookfile";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import translate from "../utils/translate";
import scraper from "../utils/scraper";
import HomeIcon from "@material-ui/icons/Home";
import CircularProgress from "@material-ui/core/CircularProgress";
import { toast } from "react-toastify";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
const queryString = require("query-string");
var iconv = require("iconv-lite");
const styles = theme => ({
  textField: { color: "#fff" },
  flex: {
    flex: 1
  },
  rectpag: {
    position: "absolute",
    bottom: 20
  },
  bottom: { position: "fixed", width: "100%", bottom: 0 },
  badge: { position: "absolute", left: 20, top: 20 },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  avatar: {
    margin: 10
  },
  bigAvatar: {
    width: 96,
    height: 126,
    marginRight: 10,
    float: "left"
  },
  rightIcon: {
    marginLeft: theme.spacing.unit
  },
  button: {
    margin: theme.spacing.unit
  },
  paper: {
    backgroundColor: "transparent",
    boxShadow: "none",
    overflow: "hidden"
  }
});
const theme = createMuiTheme({
  palette: {
    type: "dark" //
  },
  typography: { useNextVariants: true }
});
class WebPage extends React.Component {
  constructor(props) {
    super(props);
    var pars = queryString.parse(window.location.href.split("/web?")[1]);
    if (pars.url != null) {
      this.state = {
        openerr: false,
        origin: "",
        WebUrl: pars.url,
        loading: true,
        apiweb: !bookfile.op.api
      };
      this.initHref(pars.url);
    } else {
      this.state = {
        openerr: false,
        WebUrl: bookfile.WebUrl,
        origin: "",
        loading: true,
        apiweb: !bookfile.op.api
      };
    }
  }
  initHref(u) {
    if (u != null && u != undefined) {
      let url = window.location.href;
      url = url.split("?url=")[0] + "?url=" + u;
      window.history.pushState({}, null, url);
    }
  }
  componentDidMount() {
    if (!bookfile.hasTuDien && !bookfile.op.api) {
      bookfile.loadVietPhrase().then(ok => {
        if (ok) {
          toast.success("Đã tải xong dữ liệu!");
        } else {
          toast.warning(
            "Chưa có dữ liệu lưu trên máy, vui lòng tải từ điển bên dưới!"
          );
        }
      });
    }
    this.goWebUrl();
  }
  relativeToAbsolute(base, relative) {
    //make sure base ends with /
    if (relative == "./") {
      return this.state.WebUrl.substring(
        0,
        this.state.WebUrl.lastIndexOf("/") + 1
      );
    }
    if (relative.indexOf("/") == -1) {
      if (this.state.WebUrl.indexOf(".htm") != -1) {
        return (
          this.state.WebUrl.substring(
            0,
            this.state.WebUrl.lastIndexOf("/") + 1
          ) + relative
        );
      }
      if (base.indexOf("piaotian") != -1) {
        if (this.state.WebUrl.endsWith("/")) {
          return this.state.WebUrl + relative;
        }
        return this.state.WebUrl + "/" + relative;
      }
      return base + "/" + relative;
    }
    if (relative.indexOf("//") == 0) {
      return "http:" + relative;
    }
    if (base[base.length - 1] != "/") base += "/";

    //base: https://server/relative/subfolder/
    //url: https://server
    let url = base.substr(0, base.indexOf("/", base.indexOf("//") + 2));
    //baseServerRelative: /relative/subfolder/
    let baseServerRelative = base.substr(
      base.indexOf("/", base.indexOf("//") + 2)
    );
    if (relative.indexOf("/") === 0)
      //relative is server relative
      url += relative;
    else if (relative.indexOf("://") > 0)
      //relative is a full url, ignore base.
      url = relative;
    else {
      while (relative.indexOf("../") === 0) {
        //remove ../ from relative
        relative = relative.substring(3);
        //remove one part from baseServerRelative. /relative/subfolder/ -> /relative/
        if (baseServerRelative !== "/") {
          let lastPartIndex = baseServerRelative.lastIndexOf(
            "/",
            baseServerRelative.length - 2
          );
          baseServerRelative = baseServerRelative.substring(
            0,
            lastPartIndex + 1
          );
        }
      }
      url += baseServerRelative + relative; //relative is a relative to base.
    }

    return url;
  }
  abosuletUrl(url) {
    var link = document.createElement("a");
    link.href = url;
    return link.href;
  }
  getUrlParts(fullyQualifiedUrl) {
    var url = {},
      tempProtocol;
    var a = document.createElement("a");
    // if doesn't start with something like https:// it's not a url, but try to work around that
    if (fullyQualifiedUrl.indexOf("://") == -1) {
      tempProtocol = "https://";
      a.href = tempProtocol + fullyQualifiedUrl;
    } else a.href = fullyQualifiedUrl;
    var parts = a.hostname.split(".");
    url.origin = tempProtocol ? "" : a.origin;
    url.domain = a.hostname;
    url.subdomain = parts[0];
    url.domainroot = "";
    url.domainpath = "";
    url.tld = "." + parts[parts.length - 1];
    url.path = a.pathname.substring(1);
    url.query = a.search.substr(1);
    url.protocol = tempProtocol
      ? ""
      : a.protocol.substr(0, a.protocol.length - 1);
    url.port = tempProtocol
      ? ""
      : a.port
      ? a.port
      : a.protocol === "http:"
      ? 80
      : a.protocol === "https:"
      ? 443
      : a.port;
    url.parts = parts;
    url.segments = a.pathname === "/" ? [] : a.pathname.split("/").slice(1);
    url.params = url.query === "" ? [] : url.query.split("&");
    for (var j = 0; j < url.params.length; j++) {
      var param = url.params[j];
      var keyval = param.split("=");
      url.params[j] = {
        key: keyval[0],
        val: keyval[1]
      };
    }
    // domainroot
    if (parts.length > 2) {
      url.domainroot = parts[parts.length - 2] + "." + parts[parts.length - 1];
      // check for country code top level domain
      if (
        parts[parts.length - 1].length == 2 &&
        parts[parts.length - 1].length == 2
      )
        url.domainroot = parts[parts.length - 3] + "." + url.domainroot;
    }
    // domainpath (domain+path without filenames)
    if (url.segments.length > 0) {
      var lastSegment = url.segments[url.segments.length - 1];
      var endsWithFile = lastSegment.indexOf(".") != -1;
      if (endsWithFile) {
        var fileSegment = url.path.indexOf(lastSegment);
        var pathNoFile = url.path.substr(0, fileSegment - 1);
        url.domainpath = url.domain;
        if (pathNoFile) url.domainpath = url.domainpath + "/" + pathNoFile;
      } else url.domainpath = url.domain + "/" + url.path;
    } else url.domainpath = url.domain;
    return url;
  }
  redirect(e) {
    let url = e.target.getAttribute("oclick");
    if (url == null) {
      url = e.target.closest("a").getAttribute("oclick");
    }
    if (url != null) {
      if (url == "/") {
        url = this.state.origin;
      } else {
        url = this.bindUrlChap(this.state.origin, url);
      }
      this.setState(
        {
          WebUrl: url
        },
        function() {
          this.goWebUrl();
        }
      );
    }
  }
  bindUrlChap(base, url) {
    if (url == "./") {
      return this.state.WebUrl.substring(
        0,
        this.state.WebUrl.lastIndexOf("/") + 1
      );
    }
    if (url.indexOf("/") == -1) {
      if (this.state.WebUrl.indexOf(".htm") != -1) {
        return (
          this.state.WebUrl.substring(
            0,
            this.state.WebUrl.lastIndexOf("/") + 1
          ) + url
        );
      }
      if(base.match( /(77nt|77nt|shumilou.org|piaotian|ptwxz|biqugezw.net|biduo.cc|wangshugu.com|booktxt)/ )){
        return this.state.WebUrl + url;
      }
      if (base.indexOf("piaotian") != -1) {
        if (this.state.WebUrl.endsWith("/")) {
          return this.state.WebUrl + url;
        }
        return this.state.WebUrl + "/" + url;
      }
      return base + "/" + url;
    }
    if (url.indexOf("//") == 0) {
      return "http:" + url;
    }
    return this.relativeToAbsolute(base, url);
  }

  async goWebUrl() {
    let url = this.state.WebUrl || bookfile.WebUrl;
    this.setState({ origin: this.getUrlParts(url).origin, loading: true });
    this.initHref(this.state.WebUrl);
    if (!bookfile.op.api) {
      let that = this;
      axios
        .get(url, { responseType: "arraybuffer" })
        .then(function(response) {
          let data = "";
          let charset = "utf-8";
          if (url.toLowerCase().includes("sj.uukanshu")) {
            charset = "utf-8";
          } else if (
            url.toLowerCase().includes("uukanshu.com") ||
            url.toLowerCase().includes("uukanshu.net") ||
            url.toLowerCase().includes("shumil") ||
            url.toLowerCase().includes("shumilou") ||
            url.toLowerCase().includes("piaotian.com/html")
          ) {
            charset = "gb2312";
          } else if (
            url.toLowerCase().includes("shumil.com") ||
            url.toLowerCase().includes("piaotian")
          ) {
            charset = "gbk";
          }
          data = iconv.decode(new Buffer(response.data, charset), charset);
          if (bookfile.op.api) {
            that.transHtml(data);
          } else {
            that.bindHTML(
              translate.ChineseToVietPhraseOneMeaningForBrowser(data)
            );
          }
        })
        .catch(function(e) {
          this.goWebUrlAPI(url);
          //that.setState({ openerr: true, loading: false });
        });
    } else {
      this.goWebUrlAPI(url);
    }
  }
  async goWebUrlAPI(url) {
    let op = {
      url: url,
      t: "VP",
      GB2312: true,
      script: false
    };
    bookfile.getUrl(
      op,
      rp => {
        this.bindHTML(rp.data);
      },
      () => {
        this.setState({ openerr: true, loading: false });
      }
    );
  }
  transHtml(html) {
    scraper.transLate(
      html,
      data => {
        this.bindHTML(data);
      },
      error => {}
    );
  }
  bindHTML(data) {
    let wDoc = document.getElementById("webCT");
    wDoc.style.display = "none";
    wDoc.innerHTML = data;
    wDoc
      .querySelectorAll(".ad_content,.top-read-ad,#adtop,.qr.visible-lg")
      .forEach(e => e.parentNode.removeChild(e));
    var srcNodeList = wDoc.querySelectorAll("[src],[href]");
    for (let i = 0; i < srcNodeList.length; ++i) {
      var a = srcNodeList[i];
      let href = a.getAttribute("src");
      if (href != null && href.indexOf("javascript") === -1) {
        a.setAttribute("src", this.relativeToAbsolute(this.state.origin, href));
      }
      href = a.getAttribute("href");
      if (href != null && href.indexOf("javascript") === -1) {
        a.setAttribute(
          "href",
          this.relativeToAbsolute(this.state.origin, href)
        );
      }
      if (a.tagName.toLowerCase() == "a") {
        if (href != null && href.indexOf("javascript") === -1) {
          a.removeAttribute("href");
          a.setAttribute("oclick", href);
          a.addEventListener("click", this.redirect.bind(this), false);
        }
      }
    }
    //Eval Script
    //var scripts = wDoc.getElementsByTagName("script");
    //var head = wDoc.getElementsByTagName("head")[0];
    // for (let i = 0; i < scripts.length; i++) {
    //     if (scripts[i].src != null && scripts[i].src != "" && scripts[i].src.indexOf("kaspersky") == -1) {
    //         try {
    //             var sct = document.createElement('script');
    //             sct.src = this.relativeToAbsolute(this.state.origin, scripts[i].src);
    //             sct.type = 'text/javascript';
    //             sct.charset = "utf8";
    //             sct.async = 'true';
    //             scripts[i].insertBefore(sct, null);
    //             scripts[i].remove();
    //         } catch (e) {
    //             console.log(e);
    //         }
    //     }
    //     else if (scripts[i].text != null && scripts[i].text != "") {
    //         try {
    //             eval(scripts[i].text);
    //         } catch (e) {
    //             console.log(e);
    //         }
    //     }
    // }

    setTimeout(() => {
      this.setState({ loading: false });
      wDoc.style.display = "block";
    }, 300);
  }
  handleClickOpen = () => {
    this.setState({ openerr: true });
  };

  handleClose = () => {
    this.setState({ openerr: false });
  };
  _handleKeyPress = e => {
    if (e.key === "Enter") {
      this.goWebUrl(this.state.WebUrl);
    }
  };
  goBack(n) {
    this.props.history.go(n);
  }
  goHome() {
    window.location.href = bookfile.apiweb;
  }
  render() {
    const { classes } = this.props;
    return (
      <div>
        <AppBar style={{ transition: "0.5s", color: "#fff" }}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="Menu"
              className={classes.menuButton}
              onClick={() => this.goBack(-2)}
            >
              <ArrowBackIcon />
            </IconButton>
            <MuiThemeProvider theme={theme}>
              <TextField
                onChange={event =>
                  this.setState({ WebUrl: event.target.value })
                }
                onKeyPress={this._handleKeyPress}
                margin="dense"
                type="text"
                defaultValue={bookfile.WebUrl}
                fullWidth
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
                className={classes.textField}
                style={{ color: "#fff" }}
              />
            </MuiThemeProvider>
            <IconButton
              color="inherit"
              onClick={() => {
                this.goWebUrl(this.state.WebUrl);
              }}
            >
              <SearchIcon />
            </IconButton>
            <IconButton color="inherit" onClick={() => this.goHome()}>
              <HomeIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <div
          id="webCT"
          style={{
            paddingTop: 70,
            fontFamily: "arial",
            display: this.state.loading ? "none" : "block"
          }}
        />
        <Dialog id="loading" title="" open={this.state.loading}>
          <div
            style={{
              padding: "13px 13px 7px 13px",
              textAlign: "center",
              fontFamily: "arial",
              backgroundColor: "#fff",
              borderRadius: "50%"
            }}
          >
            <CircularProgress />
          </div>
        </Dialog>
        <Dialog open={this.state.openerr} onClose={this.handleClose}>
          <DialogTitle id="alert-dialog-title">
            {"Trình duyệt bị chặn quyền CORS"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Vui lòng sử dụng Chorme và cài đặt Extension
              Allow-Control-Allow-Origin ở{" "}
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://chrome.google.com/webstore/detail/allow-cors-access-control/lhobafahddgcelffkeicbaginigeejlf"
              >
                link sau
              </a>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary" autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
export default withStyles(styles, { withTheme: true })(WebPage);
